import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from '@progress/kendo-angular-l10n';
import { parse } from 'yaml';
import { CustomLanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class KendoTranslationService extends MessageService {

  languages = ['en-US', 'pl-PL', 'ru', 'sv', 'no'];
  kendoModules = ['grid', 'treelist'];
  kendoTranslations = {};

  constructor(
    private http: HttpClient,
    private langService: CustomLanguageService,
  ) {
    super();
    this.langService.multilanguageKendo = this;
    this.loadLanguages();
  }

  public override get(key: string): string {
    const langCode = this.langService.currentLanguage?.toLocaleLowerCase();
    const translation = this.kendoTranslations[langCode] ? this.kendoTranslations[langCode][key] : undefined;
    return translation;
  }

  public loadLanguages() {
    for (const language of this.languages) {
      this.kendoTranslations[language.toLowerCase()] = {};
    }
    for (const language of this.languages) {
      for (const kendoModule of this.kendoModules) {
        this.http.get('/assets/i18n/kendo/' + kendoModule + '/' + kendoModule + '.' + language + '.yml', { responseType: 'text' })
          .subscribe((yml: string) => {
            const kv = this.ymlDictToKV(parse(yml));
            this.kendoTranslations[language.toLowerCase()] = Object.assign(this.kendoTranslations[language.toLowerCase()], kv);
          });
      }
    }
  }

  private ymlDictToKV(ymlDict, prefix = '', res = {}) {
    for (const key in ymlDict) {
      let newPrefix = prefix + '.' + key;
      if (prefix === '') {
        newPrefix = key;
      }
      const val = ymlDict[key];
      if (typeof (val) === 'string') {
        res[newPrefix] = val;
      } else {
        this.ymlDictToKV(val, newPrefix, res);
      }
    }
    return res;
  }


}
