export interface MaintenanceConfiguration {
    isMaintenanceMode: boolean;
    maintenanceMessage: string;
    maintenanceType: MaintenanceType;
    maintenanceStartTime: Date | null;
    maintenanceEndTime: Date | null;
    isAnnouncement: boolean;
    announcementMessage: string;
    announcementStartTime: Date | null;
}

export enum MaintenanceType {
    Manual = 'Manual',
    Scheduled = 'Scheduled'
}
