import { Injectable } from "@angular/core";
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor, State } from "@progress/kendo-data-query";
import { KendoUtils } from "../kendo-utils";

@Injectable({
    providedIn: 'root'
})
export class FilterAndSortDescriptionService {

    public createODataWithFilters(gridState: State, additionalFilter: CompositeFilterDescriptor) {
        let oDataFilters = KendoUtils.toODataString(gridState);
        if (!additionalFilter || !additionalFilter.filters || additionalFilter.filters.length == 0) {
            return oDataFilters;
        }
        const filtersCopy = [];
        gridState.filter.filters.forEach(x => {
            filtersCopy.push(x);
        });

        const newFilter: CompositeFilterDescriptor = {
            filters: filtersCopy,
            logic: "and"
        };

        const stateCopy: State = {
            filter: newFilter,
            skip: gridState.skip,
            take: gridState.take,
            sort: gridState.sort
        };

        stateCopy.filter.filters.push(additionalFilter);
        oDataFilters = KendoUtils.toODataString(stateCopy);


        return oDataFilters;
    }

    public createFilter(fieldName: string, filterValue: unknown,
        filterOperator: 'eq' | 'neq' | 'contains' | 'gte'): FilterDescriptor {

        const filterDesc: FilterDescriptor = {
            value: filterValue,
            field: fieldName,
            operator: filterOperator,
        }
        return filterDesc;
    }

    public createCompositeFilter(fieldName: string, filterValues: unknown[],
        filterOperator: 'eq' | 'neq' | 'contains' | 'gte', logicOperator: 'and' | 'or'): CompositeFilterDescriptor {

        const filters: FilterDescriptor[] = [];

        filterValues.forEach(filterValue => {
            const filterDesc: FilterDescriptor = {
                value: filterValue,
                field: fieldName,
                operator: filterOperator,
            };
            filters.push(filterDesc);
        });

        const compositeFilter: CompositeFilterDescriptor = {
            logic: logicOperator,
            filters: filters
        };

        return compositeFilter;
    }

    public addFilter(state: State, filter: FilterDescriptor) {
        state.filter.filters = state.filter.filters.filter((o) => !this.isExactFilterAlreadyApplied(filter, o['field'], o['operator'], o['value']))
        state.filter.filters.push(filter);
    }

    public addCompositeFilter(state: State, filter: CompositeFilterDescriptor) {
        if (!this.isExactCompositeFilterAlreadyApplied(filter, state)) {
            state.filter.filters.push(filter);
        }
        return;
    }

    public replaceFilter(state: State, filter: FilterDescriptor) {
        state.filter.filters = state.filter.filters.filter((o) => !this.isFilterAlreadyApplied(filter, o['field']))
        state.filter.filters.push(filter);
    }

    public removeFilter(state: State, filter: FilterDescriptor) {
        state.filter.filters = state.filter.filters.filter((o) => !this.isFilterAlreadyApplied(filter, o['field']))
    }

    private isFilterAlreadyApplied(filter: FilterDescriptor, field: string) {
        return filter.field == field;
    }

    private isExactFilterAlreadyApplied(filter: FilterDescriptor, field: string, operator: string, value: unknown) {
        return filter.field == field && filter.operator == operator && filter.value == value;
    }
    private isExactCompositeFilterAlreadyApplied(newFilter: CompositeFilterDescriptor, state: State) {
        const currentFitlers = state.filter.filters;
        let applied = false;
        currentFitlers.forEach(filter => {
            const compositeFilter = filter as CompositeFilterDescriptor;
            if (!compositeFilter || !compositeFilter.filters) {
                return;
            }
            const currentFilterFiltering = compositeFilter.filters as FilterDescriptor[];
            const currentFilterField = currentFilterFiltering[0]?.field;

            const newFilterFilterings = newFilter.filters as FilterDescriptor[]
            const newFilterField = newFilterFilterings[0]?.field;
            if (compositeFilter.logic === newFilter.logic && currentFilterField === newFilterField) {
                applied = true;
            }
        })
        return applied;
    }

    public createSortDescriptor(fieldName: string, dir: 'asc' | 'desc'): SortDescriptor {
        const sortDesc: SortDescriptor = {
            field: fieldName,
            dir: dir
        }
        return sortDesc;
    }

    public setDefaultSortDescriptor(state: State, sortDesc: SortDescriptor) {
        if (!state.sort || state.sort.length === 0) {
            this.replaceSortDescriptor(state, sortDesc);
        }
    }

    public replaceSortDescriptor(state: State, sortDesc: SortDescriptor) {
        state.sort = [sortDesc];
    }
}
