import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { concatMap } from "rxjs/operators";
import { openOrdersDetailsFormName, openOrdersDetailsLineColumnsFormName } from "src/app/shared/constants/form-names.consts";
import { CrmAccount } from "src/app/shared/models/account";
import { NewOrderAddress } from "src/app/shared/models/address/address";
import { CosmosCustomer } from "src/app/shared/models/customer";
import { FormConfiguration } from "src/app/shared/models/form-configuration";
import { KendoGridBase } from "src/app/shared/models/kendo-grid-base";
import { PortalOrder } from "src/app/shared/models/order-creation/portal-order";
import { PortalOrderProduct } from "src/app/shared/models/order-creation/portal-order-product";
import { ColumnSettings } from "src/app/shared/models/ui/column-settings";
import { TitleValue } from "src/app/shared/models/ui/title-value";
import { SecurityService } from "src/app/shared/security/security.service";
import { ICustomerService } from "src/app/shared/services/customer/customer.service.interface";
import { KendoAlertService } from "src/app/shared/services/kendo-alerts.service";
import { CompanyAdminConfigurationStore } from "src/app/shared/stores/company-admin-configuration.store";
import { Utils } from "src/app/shared/utils";
import { IPortalOrderService } from "../services/portal-orders.service.interface";
import { productColumns } from "./order-products-grid-columns";

@Component({
    selector: 'ntw-portal-order-detail',
    templateUrl: './portal-order-detail.component.html',
})


export class PortalOrderDetailComponent extends KendoGridBase<PortalOrderProduct> implements OnInit {
    formConfig: FormConfiguration;
    columnsFormConfig: FormConfiguration;
    selectedRecentOrderId: string;
    selectedRecentOrder: PortalOrder;

    currentAccount: CrmAccount;
    currentCustomer: CosmosCustomer;

    public firstRow: TitleValue[] = [];
    public secondRow: TitleValue[] = [];
    public thirdRow: TitleValue[] = [];

    public columnSettings: ColumnSettings[];

    constructor(
        private activatedRoute: ActivatedRoute,
        private companyAdminConfigurationStore: CompanyAdminConfigurationStore,
        private customerService: ICustomerService,
        private kendoAlertService: KendoAlertService,
        private portalOrdersService: IPortalOrderService,
        private securityService: SecurityService,
        private translateService: TranslateService,
    ) {
        super();
    }

    ngOnInit() {
        this.companyAdminConfigurationStore.getFormConfiguration(openOrdersDetailsFormName).pipe(concatMap(formConfig => {
            this.formConfig = formConfig;
            return this.companyAdminConfigurationStore.getFormConfiguration(openOrdersDetailsLineColumnsFormName);
        }), concatMap(columnsFormConfig => {
            this.columnsFormConfig = columnsFormConfig;
            return this.securityService.getCurrentAccount();
        }), concatMap(currentAccount => {
            this.currentAccount = currentAccount;
            return this.customerService.getCustomerByCode(this.currentAccount.ntw_axcode);
        }), concatMap(currentCustomer => {
            this.currentCustomer = currentCustomer;
            return this.activatedRoute.paramMap;
        })).subscribe({
            next: params => {
                this.selectedRecentOrderId = params.get("id");
                this.columnSettings = FormConfiguration.applyToColumnSettings(this.columnsFormConfig, [...productColumns]);
                if (history.state.portalOrder && history.state.portalOrder.id === this.selectedRecentOrderId) {
                    this.selectedRecentOrder = history.state.portalOrder;
                    this.loading = false;
                    this.entities = this.selectedRecentOrder.orderProducts;
                    this.generateGridDataView();
                    this.fillRowsWithData();
                }
                else {
                    this.getEntitiesForAccount();
                }
            },
            error: error => {
                this.kendoAlertService.showErrorAlert(error);
            }
        });
    }

    getEntitiesForAccount() {
        this.portalOrdersService.GetPortalOrderDetailsById(this.currentAccount.ntw_axcode, this.selectedRecentOrderId).subscribe({
            next: retrievedOrder => {
                if (!retrievedOrder) {
                    this.kendoAlertService.showErrorAlert("alerts.errorDuringDataRetrieve");
                }
                this.loading = false;
                if (!retrievedOrder) {
                    return;
                }
                this.selectedRecentOrder = retrievedOrder;
                this.entities = retrievedOrder.orderProducts;
                this.generateGridDataView();
                this.fillRowsWithData();
            },
            error: error => {
                this.kendoAlertService.showErrorAlert(error);
                this.loading = false;
            }
        });
    }

    public fillRowsWithData() {
        this.firstRow = [];
        this.secondRow = [];
        this.thirdRow = [];

        const firstRow = [
            {
                field: "orderWarehouse",
                title: "portalOrder.orderWarehouse",
                value: this.selectedRecentOrder.warehouse?.warehouseName,
                subtitle: ''
            },
            {
                field: "isWarehousePickUpPoint",
                title: "portalOrder.isWarehousePickUpPoint",
                value: this.selectedRecentOrder.isWarehousePickUpPoint,
                subtitle: ''
            },
            {
                field: "deliveryAddress",
                title: "portalOrder.deliveryAddress",
                value: this.addressToString(this.selectedRecentOrder.deliveryAddress),
                subtitle: ''
            }
        ];

        const secondRow = [
            {
                field: "customerReference",
                title: "portalOrder.customerReference",
                value: this.selectedRecentOrder.customerReference,
                subtitle: ''
            },
            {
                field: "customerRequisition",
                title: "portalOrder.customerRequisition",
                value: this.selectedRecentOrder.customerRequisition,
                subtitle: ''
            },
            {
                field: "requestedDeliveryDate",
                title: "portalOrder.requestedDeliveryDate",
                value: Utils.FormatDate(this.selectedRecentOrder.requestedDeliveryDate),
                subtitle: ''
            }
        ];

        const thirdRow = [
            {
                field: "totalProducts",
                title: "portalOrder.totalProducts",
                value: Utils.FormatNumber(this.selectedRecentOrder.totalProducts),
                subtitle: ''
            },
            {
                field: "totalValue",
                title: Utils.TryAddCurrencyCode(this.translateService.instant("portalOrder.totalValue"), this.currentCustomer),
                value: Utils.FormatNumber(this.selectedRecentOrder.totalValue, 2),
                subtitle: ''
            },
            {
                field: "totalVat",
                title: Utils.TryAddCurrencyCode(this.translateService.instant("portalOrder.totalVat"), this.currentCustomer),
                value: Utils.FormatNumber(this.selectedRecentOrder.totalVat, 2),
                subtitle: ''
            },
            {
                field: "totalValueWithVat",
                title: Utils.TryAddCurrencyCode(this.translateService.instant("portalOrder.totalValueWithVat"), this.currentCustomer),
                value: Utils.FormatNumber(this.selectedRecentOrder.totalValueWithVat, 2),
                subtitle: ''
            }
        ];

        this.firstRow = FormConfiguration.applyToTitleValues(this.formConfig, firstRow);
        this.secondRow = FormConfiguration.applyToTitleValues(this.formConfig, secondRow);
        this.thirdRow = FormConfiguration.applyToTitleValues(this.formConfig, thirdRow);
    }

    public addressToString(address: NewOrderAddress) {
        if (!address) {
            return "";
        }
        const addressString = address.street + "\n"
            + address.zipCode + " " + address.city;
        return addressString;
    }
}
