import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { LanguageCodes } from '../constants/language-codes';
import { KendoTranslationService } from './kendo-translation.service';

@Injectable({
  providedIn: 'root'
})

export class CustomLanguageService {
  constructor(private translateService: TranslateService) {
    if (!this.translateService.getDefaultLang()) {
      this.translateService.setDefaultLang(this.defaultLanguage);
    }
  }

  private readonly _defaultLanguage = LanguageCodes.EnglishUSLanguageCode;

  public get defaultLanguage() {
    return this._defaultLanguage;
  }

  private _currentLanguage: string;

  public set currentLanguage(languageCode: string) {
    this._currentLanguage = languageCode;

    this.storeLanguageInMemory(languageCode);
    this.translateService.use(languageCode);
    if (this._multilanguageKendo) {
      this._multilanguageKendo.notify();
    }
  }

  public get currentLanguage() {
    this._currentLanguage = this.getLanguageFromMemory();
    return this._currentLanguage;
  }

  private _multilanguageKendo: KendoTranslationService;

  public set multilanguageKendo(value: KendoTranslationService) {
    this._multilanguageKendo = value;
    this._multilanguageKendo.notify();
  }

  public getTranslation(key: string) {
    return this.translateService.instant(key);
  }

  storeLanguageInMemory(language: string) {
    localStorage.setItem('currentLanguage', JSON.stringify(language));
  }

  private getLanguageFromMemory(): string {
    const languageJson = localStorage.getItem('currentLanguage');
    if (!languageJson) {
      const defaultLanguage = this.translateService.getDefaultLang();
      return defaultLanguage ?? LanguageCodes.EnglishUSLanguageCode;
    }
    const retrievedLangCode = JSON.parse(languageJson) as string;
    return retrievedLangCode;
  }

  public get browserLanguage(): string {
    switch (navigator.language.substring(0, 2)) {
      case "en":
        return LanguageCodes.EnglishUSLanguageCode;
      case "nb":
        return LanguageCodes.NorwegianLanguageCode;
      case "nn":
        return LanguageCodes.NorwegianLanguageCode;
      case "no":
        return LanguageCodes.NorwegianLanguageCode;
      case "pl":
        return LanguageCodes.PolishLanguageCode;
      case "sv":
        return LanguageCodes.SwedishLanguageCode;
      case "ru":
        return LanguageCodes.RussianLanguageCode;
      default:
        return this._defaultLanguage;
    }
  }
}
