import { ColumnSettings } from "src/app/shared/models/ui/column-settings";

export const orderHeaderColumns: ColumnSettings[] = [
    {
        field: "bkOrderDateKey", minResizableWidth: 110, width: 110, filter: "date", format: "dd/MM/yyyy",
        title: "order.bkOrderDateKey"
    },
    {
        field: "CustomerRequisition", minResizableWidth: 130, width: 130,
        title: "order.CustomerRequisition"
    },
    {
        field: "CustomerReference", minResizableWidth: 110, width: 110,
        title: "order.CustomerReference"
    },
    {
        field: "bkSalesOrderHeader", minResizableWidth: 110, width: 110,
        title: "order.Id"
    },
    {
        field: "TotalLines", filter: "numeric", minResizableWidth: 90, width: 90,
        title: "order.TotalLines", sortable: false, filterable: false
    },
    {
        field: "TotalAmount", filter: "numeric", minResizableWidth: 100, width: 100,
        format: "{0:n2}", title: "order.TotalAmount", sortable: false, filterable: false
    },
    {
        field: "TotalVat", filter: "numeric", minResizableWidth: 100, width: 100,
        format: "{0:n2}", title: "order.TotalVat", sortable: false, filterable: false
    },
    {
        field: "TotalAmountIncludingVat", filter: "numeric", minResizableWidth: 100, width: 100,
        format: "{0:n2}", title: "order.TotalAmountIncludingVat", sortable: false, filterable: false
    },
    {
        field: "SalesStatus", minResizableWidth: 90, width: 90,
        title: "order.Status"
    },
    {
        field: "bkRequestedShipmentDateKey", filter: "date", format: "dd/MM/yyyy", minResizableWidth: 110, width: 110,
        title: "order.bkRequestedShipmentDateKey"
    },
];
