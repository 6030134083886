import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ColorShadeValue } from '../../models/colorshade/colorshade-value';
import { ODataResponse } from '../../models/odata-response';
import { IColorshadeService } from './colorshade.service.interface';

@Injectable({
  providedIn: 'root'
})

export class ColorshadeService implements IColorshadeService {
  private zoneNames = ["Baltics standard", "Russia standard"]
  private baseUrl: string;

  constructor(protected httpClient: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
  }

  public getColorShadesByEanCode(eanCode: string): Observable<ODataResponse<ColorShadeValue[]>> {
    const zoneNamesFilter = this.buildZoneNameFilter();
    const url = this.baseUrl + "colorshades" + `?$filter=EAN eq '${eanCode}' ` + zoneNamesFilter;

    return this.httpClient.get<ODataResponse<ColorShadeValue[]>>(url);
  }

  protected buildZoneNameFilter() {
    let zoneNamesFilter = "and (ZoneNames in (";
    this.zoneNames.forEach(zoneName => {
      zoneNamesFilter += `'${zoneName}'`;
      if (this.zoneNames.indexOf(zoneName) != this.zoneNames.length - 1) {
        zoneNamesFilter += ", ";
      }
    });
    zoneNamesFilter += "))";
    return zoneNamesFilter;
  }
}
