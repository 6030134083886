import { ColumnSettings } from "src/app/shared/models/ui/column-settings";

export const selectProductColumns: ColumnSettings[] = [
    {
        field: "AXCode", width: 110, minResizableWidth: 110,
        title: "product.AXCode",
        multiselection: true
    },
    {
        field: "Name", width: 150, minResizableWidth: 150,
        title: "product.Name",
        multiselection: true
    },
    {
        field: "BrandName", width: 90, minResizableWidth: 90,
        title: "product.BrandName",
        multiselection: true
    },
    {
        field: "FamilyName", width: 90, minResizableWidth: 90,
        title: "product.FamilyName",
        multiselection: true
    },
    {
        field: "PalletSizeName", width: 90, minResizableWidth: 90,
        title: "product.PalletSizeName",
        multiselection: true,
        filterable: true, sortable: false
    },
    {
        field: "Inventory", width: 90, minResizableWidth: 90,
        loadingField: "InventoryLoading",
        errorField: "InventoryNotAvailable",
        title: "orderProcess.products.inventory",
        multiselection: true,
        filterable: false, sortable: false,
        format: "{0:n0}"
    },
    {
        field: "SalesPrice", width: 90, minResizableWidth: 90,
        loadingField: "SalesPriceLoading",
        errorField: "SalesPriceNotAvailable",
        title: "portalOrderLine.salesPrice",
        multiselection: true,
        filterable: false, sortable: false,
        format: "{0:n2}"
    },
    {
        field: "UnitNetPrice", width: 90, minResizableWidth: 90,
        loadingField: "UnitNetPriceLoading",
        errorField: "UnitNetPriceNotAvailable",
        title: "portalOrderLine.unitNetPrice",
        multiselection: true,
        filterable: false, sortable: false,
        format: "{0:n2}"
    },
    {
        field: "Quantity", width: 90, minResizableWidth: 90,
        title: "orderProcess.products.quantity",
        multiselection: true,
        filterable: false, sortable: false,
        editor: "numeric",
        editorSettings: {
            displayValue: false,
            autoCorrect: true,
            decimals: 0,
            format: 'n0',
            min: 1,
            max: 999999
        }
    },
]
