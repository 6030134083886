<ntw-loading-panel *ngIf="loading"></ntw-loading-panel>
<ng-container *ngIf="!loading">
    <div class="row mt-3">    
        <div class="col col-lg-10 pb-2">
            <label class="headerBig">{{ 'invoice.invoiceData' | translate }}</label>
        </div>
    </div>
    <div class="row mx-2 my-3">
        <ng-container *ngFor="let value of firstRow; index as i">
            <div class="col-12 col-sm-4">
                <ntw-title-value [title]="value.title" [value]="value.value"></ntw-title-value>
            </div>
        </ng-container>
    </div>
    <div class="row mx-2 my-3">
        <ng-container *ngFor="let value of secondRow; index as i">
            <div class="col-12 col-sm-4">
                <ntw-title-value [titleValue]="value"></ntw-title-value>
            </div>
        </ng-container>
    </div>
    <div class="row mx-2 my-3">
        <ng-container *ngFor="let value of thirdRow; index as i">
            <div class="col-12 col-sm-4">
                <ntw-title-value [titleValue]="value"></ntw-title-value>
            </div>
        </ng-container>
    </div>
    <div class="row mx-2 my-3">
        <div class="d-flex flex-row">
            <ntw-loading-button class="me-1" buttonClass="primaryButton" (click)="sendInvoice()"
                [disabled]="!canCreatePrintInvoiceRequest()" [loading]="isSubmittingPrintInvoiceRequest"
                [text]="'order.sendInvoice' | translate">
            </ntw-loading-button>
        </div>
    </div>
    <div class="row my-3">    
        <div class="col col-lg-10 pb-2">
            <label class="headerBig">{{ 'invoice.invoiceLines' | translate }}</label>
        </div>
    </div>
    <div>
        <ntw-grid-template [currentAccount]="this.currentAccount" [columnSettings]="this.columnSettings"
            idFieldName="SalesLine" [selectableSettings]="{enabled: false}" [entities]="this.entities"
            [dataGridView]="this.dataGridView" [summaries]="this.summaries" [loading]="this.loading"
            [dataStateChange]="this.dataStateChange" [kendoGridState]="this.kendoGridState"
            [showCheckBoxColumn]="false" [showMenu]="false" [drillDownEnabled]="false">
        </ntw-grid-template>
    </div>
</ng-container>