<div class="d-flex align-items-center w-100">
    <button kendoButton (click)="isTreeViewVisible = !isTreeViewVisible" [disabled]="disabled" [ngClass]="{
        'primaryButton': selectedItem,
        'tertiaryButton': !selectedItem
    }">
        <kendo-svg-icon [icon]="this.icons.filter"></kendo-svg-icon>
    </button>
    <div class="breadcrumbs-container h-100" *ngIf="isBreadcrumbVisible">
        <ntw-sku-filter-breadcrumbs [items]="selectedItemHierarchy" (itemClick)="breadcrumbClicked($event)">
        </ntw-sku-filter-breadcrumbs>
    </div>
</div>

<div *ngIf="isTreeViewVisible && !disabled" class="treeview-container">
    <button class="primaryButton m-2 d-flex justify-content-center align-items-center" [style.height.px]="30"
        (click)="clearSelection()">
        <kendo-svg-icon [icon]="icons.filterClear" size="medium"></kendo-svg-icon>
        Clear filter
    </button>
    <kendo-treeview class="sku-filter-treeview" kendoTreeViewExpandable kendoTreeViewSelectable
        kendoTreeViewFlatDataBinding [nodes]="data" idField="code" textField="text" parentIdField="parentCode"
        selectBy="code" [(selectedKeys)]="selectedKeys" [(expandedKeys)]="expandedKeys"
        (selectionChange)="onSelectionChanged()">
    </kendo-treeview>
</div>
