import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { SVGIcon, aggregateFieldsIcon, cartIcon, folderOpenIcon, infoCircleIcon, layoutStackedIcon, menuIcon } from '@progress/kendo-svg-icons';
import { SalesDistrict } from 'src/app/shared/constants/sales-district';
import { CrmAccount } from 'src/app/shared/models/account';
import { CosmosCustomer } from 'src/app/shared/models/customer';
import { SecurityService } from 'src/app/shared/security/security.service';
import { ICustomerService } from 'src/app/shared/services/customer/customer.service.interface';
import { NavigationOption } from './navigation-option';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'ntw-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit, AfterViewInit {
  @ViewChild('menuOptions') public menuOptionsDropdown: DropDownListComponent;
  currentAccount: CrmAccount;
  currentCustomer: CosmosCustomer;
  customerServiceDialogOpened: boolean = false;

  public icons = {
    menu: menuIcon
  }

  public openCustomerServiceDialog = () => {
    this.customerServiceDialogOpened = true;
  }

  public closeCustomerServiceDialog = () => {
    this.customerServiceDialogOpened = false;
  }

  public isCustomerServiceTabVisible = () => {
    if (!this.currentCustomer) {
      return false;
    }

    const salesDistrict = this.currentCustomer.SalesDistrict;
    return salesDistrict && (salesDistrict.startsWith(SalesDistrict.AlcroSweden) || salesDistrict.startsWith(SalesDistrict.BeckersSweden))
  }

  private getAllOptions(): NavigationOption[] {
    return [{ label: 'navigationTopBar.home', navigateTo: "", icon: layoutStackedIcon, isVisible: false },
    {
      label: 'navigationTopBar.recentOrders', navigateTo: "openorders", icon: cartIcon,
      isVisible: false
    },
    {
      label: 'navigationTopBar.orderHistory', navigateTo: "orders", icon: aggregateFieldsIcon,
      isVisible: false, isVisibleFunction: () => this.securityService.canViewOrders()
    },
    {
      label: 'navigationTopBar.invoices', navigateTo: "invoices", icon: folderOpenIcon,
      isVisible: false, isVisibleFunction: () => this.securityService.canViewInvoices()
    },
    //hidden: task 52609
    //{ label: 'navigationTopBar.tasks', navigateTo: "tasks", icon: fileAscxIcon, isVisible: false },
    //hidden: task 53501
    //{ label: 'navigationTopBar.products', navigateTo: "products", icon: filePptIcon, isVisible: false }
    {
      label: 'navigationTopBar.customerService', navigateToFunction: this.openCustomerServiceDialog, icon: infoCircleIcon,
      isVisible: false, isVisibleFunction: this.isCustomerServiceTabVisible
    }
    ];
  }

  public options: NavigationOption[] = [];
  public menuOptions: NavigationOption[] = [];
  public isMenuExpanded = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private customerService: ICustomerService,
    private securityService: SecurityService,
    private translateService: TranslateService
  ) {
    this.securityService.getCurrentAccount().subscribe(currentAccount => {
      this.currentAccount = currentAccount;
      this.customerService.getCustomerByCode(currentAccount?.ntw_axcode).subscribe(customer => {
        this.currentCustomer = customer;
      });
    })
  }

  ngOnInit(): void {
    this.options = this.getAllOptions().slice();
    this.menuOptions = this.getAllOptions().slice();
    this.translateService.onLangChange.subscribe(() => this.manageNavigationBar(window.innerWidth));
  }

  ngAfterViewInit(): void {
    this.manageNavigationBar(window.innerWidth);
    this.changeDetector.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.manageNavigationBar(event.currentTarget.innerWidth);
  }

  manageNavigationBar(windowWidth: number) {
    const averageOptionWidth = this.options.reduce((acc, option) => acc + 28 + this.translateService.instant(option.label).length * 10, 0) / this.options.length;
    const newOrderButtonTextLength = this.translateService.instant('order.newOrder').length;
    const newOrderButtonWidth = Math.max(125, 35 + newOrderButtonTextLength * 9.5);
    const accountContextChooserWidth = windowWidth < 1200 ? 144 : 200;
    const staticPartsWidth = 32 + 121 + 18 + accountContextChooserWidth + newOrderButtonWidth;

    const availableSpace = windowWidth - staticPartsWidth;
    const maxNumberOfVisibleTabs = Math.floor(availableSpace / averageOptionWidth);

    const visibleOptions = this.options.filter(o => !o.isVisibleFunction || o.isVisibleFunction());

    for (let i = 0; i < this.options.length; i++) {
      const shouldBeVisible = !this.options[i].isVisibleFunction || this.options[i].isVisibleFunction();
      const canBeVisible = visibleOptions.length <= maxNumberOfVisibleTabs;

      this.options[i].isVisible = canBeVisible && shouldBeVisible;
      this.menuOptions[i].isVisible = !canBeVisible && shouldBeVisible;
    }

    if (this.menuOptionsDropdown) {
      this.menuOptionsDropdown.data = this.menuOptions.filter(item => item.isVisible);
      this.menuOptionsDropdown.disabled = this.menuOptionsDropdown.data.length === 0;
    }
  }

  getIcon(option: NavigationOption): SVGIcon {
    return option.icon;
  }

  expandMenuSelection(): void {
    this.isMenuExpanded = !this.isMenuExpanded;
  }

  getLink(option: NavigationOption): string | null {
    if (!this.currentAccount) {
      return '';
    }

    if (option.navigateTo != null) {
      if (option.navigateTo == "") {
        return 'account/' + this.currentAccount?.accountid;
      }
      return 'account/' + this.currentAccount?.accountid + '/' + option.navigateTo;
    }
    else {
      return null;
    }
  }

  public isMenuButtonHidden(): boolean {
    return !this.currentAccount || this.menuOptions.filter(o => o.isVisible).length <= 0;
  }
}
