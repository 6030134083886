import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { cartIcon, infoSolidIcon } from '@progress/kendo-svg-icons';
import { Subscription } from 'rxjs';
import { BootstrapBreakpoints } from 'src/app/shared/constants/bootstrap-breakpoints';
import { ProductWithInventoryAndQuantity } from 'src/app/shared/models/order-creation/product-with-quantity';
import { PimproductsService } from 'src/app/shared/services/pimproducts/pimproducts.service';

@Component({
  selector: 'ntw-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss', '../../styles/button.scss']
})
export class ProductCardComponent implements OnInit, OnDestroy {
  @Input() product: ProductWithInventoryAndQuantity;
  @Output() addToCart = new EventEmitter<ProductWithInventoryAndQuantity>();
  @Output() viewDetails = new EventEmitter<ProductWithInventoryAndQuantity>();
  private pimSubscription?: Subscription;

  loading = true;
  imageLoading = true;
  imageUrl: string;
  imageOpacity = 1;
  cardWidth = '280px';
  quantity = 1;

  icons = {
    cart: cartIcon,
    info: infoSolidIcon
  };

  constructor(
    private breakpointObserver: BreakpointObserver,
    private pimProductsService: PimproductsService
  ) { }

  ngOnInit(): void {
    this.setupBreakpointObserver();
    this.fetchImageUrl();
  }

  ngOnDestroy(): void {
    this.pimSubscription?.unsubscribe();
  }

  private setupBreakpointObserver() {
    this.breakpointObserver.observe([
      BootstrapBreakpoints.xxs,
      BootstrapBreakpoints.xs,
      BootstrapBreakpoints.sm,
      BootstrapBreakpoints.md,
      BootstrapBreakpoints.lg,
      BootstrapBreakpoints.xl,
      BootstrapBreakpoints.xxl
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[BootstrapBreakpoints.xxs]) {
          this.cardWidth = '180px';
        }
        else if (result.breakpoints[BootstrapBreakpoints.xs]) {
          this.cardWidth = '200px';
        }
        else if (result.breakpoints[BootstrapBreakpoints.sm]) {
          this.cardWidth = '230px';
        }
        else if (result.breakpoints[BootstrapBreakpoints.md]) {
          this.cardWidth = '230px';
        }
        else if (result.breakpoints[BootstrapBreakpoints.lg]) {
          this.cardWidth = '280px';
        }
        else if (result.breakpoints[BootstrapBreakpoints.xl]) {
          this.cardWidth = '250px';
        }
        else if (result.breakpoints[BootstrapBreakpoints.xxl]) {
          this.cardWidth = '280px';
        }
      }
    });
  }

  private fetchImageUrl(): void {
    this.pimSubscription = this.pimProductsService.get(this.product.AXCode).subscribe({
      next: (pimProduct) => {
        if (!pimProduct) {
          this.loading = false;
          return;
        }
        const assets = pimProduct.mediaRelation.sort((a, b) => parseInt(b.fileId) - parseInt(a.fileId)); // sort by fileId descending
        this.imageUrl = pimProduct.mainImageURL ?? assets.find(asset => asset.type === 'Can Image')?.staticUrl;
        this.loading = false;
      },
      error: error => {
        console.error('Error while getting PIM product', error);
        this.loading = false;
      }
    });
  }

  public onImageLoad(): void {
    this.imageLoading = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onImageError(event: any) {
    this.imageOpacity = 0.2;
    event.target.src = 'assets/images/noimage.png';
  }

  public onAddToCart(): void {
    this.addToCart.emit({ ...this.product, Quantity: this.quantity });
  }

  public onViewDetails(): void {
    this.viewDetails.emit(this.product);
  }
}
