import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CompanyAdminConfiguration } from '../../models/company-admin-configuration';
import { ODataResponse } from '../../models/odata-response';
import { ICompanyAdminConfigurationService } from './company.admin.configuration.service.interface';

@Injectable({
  providedIn: 'root'
})
export class CompanyAdminConfigurationService implements ICompanyAdminConfigurationService {
  private _baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this._baseUrl = environment.apiBaseUrl + `companiesadminconfigurations`;
  }

  public get(bkCompanyKey: string): Observable<CompanyAdminConfiguration> {
    const url = this._baseUrl
      + `?$top=1`
      + `&$filter=(bkCompanyKey eq '${bkCompanyKey}')`;

    return this.httpClient.get<ODataResponse<CompanyAdminConfiguration[]>>(url).pipe(map(response => {
      const config = response?.value[0];

      if (!response) {
        throw new Error('alerts.failedToFetchCompanyConfiguration')
      }

      if (config?.maintenanceConfiguration?.maintenanceStartTime) {
        config.maintenanceConfiguration.maintenanceStartTime = new Date(config.maintenanceConfiguration.maintenanceStartTime);
      }
      if (config?.maintenanceConfiguration?.maintenanceEndTime) {
        config.maintenanceConfiguration.maintenanceEndTime = new Date(config.maintenanceConfiguration.maintenanceEndTime);
      }
      if (config?.maintenanceConfiguration?.announcementStartTime) {
        config.maintenanceConfiguration.announcementStartTime = new Date(config.maintenanceConfiguration.announcementStartTime);
      }
      if (config?.notificationConfiguration?.startTime) {
        config.notificationConfiguration.startTime = new Date(config.notificationConfiguration.startTime);
      }
      if (config?.notificationConfiguration?.endTime) {
        config.notificationConfiguration.endTime = new Date(config.notificationConfiguration.endTime);
      }

      if (config && (!config.dashboardCards || config.dashboardCards.length === 0)) {
        config.dashboardCards = [];
      }

      return config;
    }));
  }
}
