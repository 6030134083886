import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { CompanyAdminConfiguration } from 'src/app/shared/models/company-admin-configuration';
import { FormConfiguration } from '../models/form-configuration';
import { ICompanyAdminConfigurationService } from '../services/company-admin-configuration/company.admin.configuration.service.interface';
import { KendoAlertService } from '../services/kendo-alerts.service';
import { Store } from './store.base';

@Injectable({
  providedIn: 'root'
})
export class CompanyAdminConfigurationStore extends Store<CompanyAdminConfiguration> {
  private bkCompanyKey: string;

  constructor(
    private companyAdminConfigurationService: ICompanyAdminConfigurationService,
    private kendoAlertService: KendoAlertService
  ) {
      super();
  }

  public load(bkCompanyKey: string): Observable<CompanyAdminConfiguration> {
    if (this.bkCompanyKey === bkCompanyKey) {
      return this.get();
    }

    this.loading.next(true);

    this.companyAdminConfigurationService.get(bkCompanyKey).subscribe(config => {
      this.cachedItem.next(config);
      this.bkCompanyKey = bkCompanyKey;
      this.loading.next(false);
      if (!config) {
        this.kendoAlertService.showErrorAlert("alerts.companyAdminConfigurationNotFound");
      }
      else if (!config.vatRate) {
        this.kendoAlertService.showErrorAlert("alerts.vatRateNotFound");
      }
    });

    return this.get();
  }

  public getFormConfiguration(formName: string): Observable<FormConfiguration> {
    return this.get().pipe(concatMap(companyAdminConfiguration => {
      const formConfig = companyAdminConfiguration?.formConfigurations?.find(fc => fc.name === formName);
      return formConfig ? of(formConfig) : of({ name: formName } as FormConfiguration);
    }));
  }
}
