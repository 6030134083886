import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { zip } from 'rxjs';
import { CompanyAdminConfiguration } from 'src/app/shared/models/company-admin-configuration';
import { PortalOrder } from 'src/app/shared/models/order-creation/portal-order';
import { Selection } from 'src/app/shared/models/order-creation/selection';
import { Warehouse } from 'src/app/shared/models/warehouse';
import { KendoAlertService } from 'src/app/shared/services/kendo-alerts.service';
import { CompanyAdminConfigurationStore } from '../../shared/stores/company-admin-configuration.store';
import { IOrderProcessService } from '../services/order.process.service.interface';
import { infoCircleIcon } from '@progress/kendo-svg-icons';

@Component({
    templateUrl: './select-warehouse.component.html',
    selector: 'ntw-order-select-warehouse',
    styleUrls: ['./select-warehouse.component.scss']
})
export class SelectWarehouseComponent implements OnInit, OnDestroy {
    @Input() portalOrder: PortalOrder;
    @Output() portalOrderChange = new EventEmitter<PortalOrder>();

    @Input() isReadOnly: boolean = false;

    loading = true;
    companyAdminConfiguration: CompanyAdminConfiguration = null;
    warehouses: Warehouse[] = [];
    displayedWarehouses: Warehouse[] = [];

    public icons = {
        info: infoCircleIcon
    }

    constructor(private orderProcessService: IOrderProcessService,
        private kendoAlertService: KendoAlertService,
        private companyAdminConfigurationStore: CompanyAdminConfigurationStore) { }

    ngOnInit(): void {
        this.getWarehouses();
    }

    ngOnDestroy(): void {
        this.warehouses.forEach(w => w.isChecked = false);
    }

    public getPortalOrderWarehouse(): Selection {
        return this.portalOrder.warehouse as unknown as Selection;
    }

    private getWarehouses() {
        this.loading = true;
        const $warehouses = this.orderProcessService.getWarehouses();
        const $companyAdminConfiguration = this.companyAdminConfigurationStore.get();

        zip($warehouses, $companyAdminConfiguration).subscribe({
            next: ([warehouses, companyAdminConfiguration]) => {
                this.warehouses = warehouses;
                this.companyAdminConfiguration = companyAdminConfiguration;
                this.filterWarehouses();
                this.loading = false;
            }, error: error => {
                this.kendoAlertService.showErrorAlert(error);
                this.loading = false;
            }
        });
    }

    private filterWarehouses() {
        this.displayedWarehouses = this.warehouses.filter(w => {
            const warehouseConfiguration = this.companyAdminConfiguration.warehouseConfigurations.find(wc => wc.bkWarehouseKey === w.bkWarehouseKey);

            return warehouseConfiguration && warehouseConfiguration.visibleInSalesOrder && (!this.portalOrder.isWarehousePickUpPoint || warehouseConfiguration.isPickUp);
        });

        if (this.portalOrder.warehouse) {
            const found = this.displayedWarehouses.find(o => o.bkWarehouseKey === this.portalOrder.warehouse.bkWarehouseKey);
            if (found) {
                found.isChecked = true;
            } else {
                this.portalOrder.warehouse = null;
                this.portalOrderChange.emit(this.portalOrder);
            }
        }
        else if (this.displayedWarehouses.length === 1) {
            this.selectDefaultWarehouse();
        }
    }

    private selectDefaultWarehouse() {
        const firstWarehouse = this.displayedWarehouses[0];
        firstWarehouse.isChecked = true;
        this.portalOrder.warehouse = firstWarehouse;
        this.portalOrderChange.emit(this.portalOrder);
    }

    public onChangeIsPickupAddress() {
        this.loading = true;
        this.filterWarehouses();
        this.loading = false;
    }
}
