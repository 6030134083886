import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerSettings } from '../../models/customer-settings';
import { ODataResponse } from '../../models/odata-response';
import { KendoAlertService } from '../kendo-alerts.service';
import { ICustomerSettingsService } from './customer-settings.service.interface';

@Injectable({
    providedIn: 'root'
})
export class CustomerSettingsService implements ICustomerSettingsService {
    public baseUrl: string;

    constructor(
        private httpClient: HttpClient,
        private kendoAlertService: KendoAlertService
    ) {
        this.baseUrl = environment.apiBaseUrl + "customersettings";
    }

    public create(customerSettings: CustomerSettings): Observable<unknown> {
        const url = this.baseUrl;
        return this.httpClient.post(url, customerSettings);
    }
    public update(customerSettings: CustomerSettings): Observable<unknown> {
        const url = this.baseUrl + "/update";
        return this.httpClient.patch(url, customerSettings);
    }

    public get(bkCustomerKey: string): Observable<CustomerSettings | undefined> {
        const url = this.baseUrl
            + `?$top=1`
            + `&$filter=bkCustomerKey eq '${bkCustomerKey}'`;

        return this.httpClient.get<ODataResponse<CustomerSettings[]>>(url).pipe(
            map(response => {
                if (response && response.value && response.value[0]) {
                    const settings = response.value[0];
                    return settings;
                }
                return undefined;
            }),
            catchError(error => {
                this.kendoAlertService.showErrorAlert(error);
                return of(undefined);
            })
        );
    }
}
