export enum LanguageCodes {
    EnglishUSLanguageCode = "en-US",
    NorwegianLanguageCode = "no",
    PolishLanguageCode = "pl-PL",
    SwedishLanguageCode = "sv",
    RussianLanguageCode = "ru"
}

export enum NameFieldLanguageCodes {
    EnglishUSNameField = "Name_EnUs",
    NorwegianNameField = "Name_NbNo",
    PolishNameField = "Name_Pl",
    SwedishNameField = "Name_Sv",
    RussianNameField = "Name_Ru"
}
