import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewOrderAddress } from 'src/app/shared/models/address/address';
import { Warehouse } from 'src/app/shared/models/warehouse';

@Injectable({
  providedIn: 'root'
})
export abstract class IOrderProcessService {
  public abstract getWarehouses(): Observable<Warehouse[]>;
  public abstract getAddresses(accountKey: string): Observable<NewOrderAddress[]>;
}
