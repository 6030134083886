import { Injectable } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs/internal/Observable';
import { PortalOrder } from 'src/app/shared/models/order-creation/portal-order';
@Injectable({
    providedIn: 'root'
})

export abstract class IPortalOrderService {
    baseUrl: string;

    public abstract GetCustomerPortalOrders(customerCode: string): Observable<PortalOrder[]>
    public abstract GetCustomerPortalOrdersWithGridState(customerCode: string, gridState: State): Observable<PortalOrder[]>
    public abstract GetPortalOrderDetailsById(customerCode: string, recentOrderId: string): Observable<PortalOrder>;
    public abstract GetDraftOrderByUserId(userId: string, customerCode: string): Observable<PortalOrder>;
    public abstract CreateOrder(order: PortalOrder): Observable<CreateOrderResponse>;
    public abstract UpdateOrder(order: PortalOrder): Observable<unknown>;
    public abstract SubmitOrder(order: PortalOrder): Observable<unknown>;
    public abstract DeleteOrder(order: PortalOrder): Observable<unknown>;
    public abstract setStatusName(order: PortalOrder);
    public abstract calculateFieldsFromProducts(order: PortalOrder);
}

export interface CreateOrderResponse {
    message?: string
}
