import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "../app-routing.module";
import { SharedModule } from "../shared/shared.module";
import { PortalOrderDetailComponent } from "./portal-order-detail/portal-order-detail.component";
import { PortalOrderListComponent } from "./portal-order-list/portal-order-list.component";
import { PortalOrderService } from "./services/portal-orders.service";
import { IPortalOrderService } from "./services/portal-orders.service.interface";

@NgModule({
    declarations: [
        PortalOrderListComponent, PortalOrderDetailComponent
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        SharedModule
    ],
    providers: [
        { provide: IPortalOrderService, useClass: PortalOrderService }
    ]
})
export class RecentOrderModule { }
