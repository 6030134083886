<ntw-loading-panel *ngIf="loading"></ntw-loading-panel>
<ng-container *ngIf="!loading">
    <div class="row mt-3">    
        <div class="col col-lg-10 pb-2">
            <label class="headerBig">{{ 'order.orderData' | translate }}</label>
        </div>
    </div>
    <div class="row mx-2 my-3">
        <ng-container *ngFor="let value of firstRow; index as i">
            <div class="col-12 col-sm-6 col-lg-3">
                <ntw-title-value [title]="value.title" [value]="value.value"></ntw-title-value>
            </div>
        </ng-container>
    </div>
    <div class="row mx-2 my-3">
        <ng-container *ngFor="let value of secondRow; index as i">
            <div class="col-12 col-sm-6 col-lg-3">
                <ntw-title-value [titleValue]="value"></ntw-title-value>
            </div>
        </ng-container>
    </div>
    <div class="row mx-2 my-3">
        <ng-container *ngFor="let value of thirdRow; index as i">
            <div class="col-12 col-sm-6 col-lg-3">
                <ntw-title-value [titleValue]="value"></ntw-title-value>
            </div>
        </ng-container>
    </div>
    <div class="row mx-2 my-3">
        <div class="d-flex flex-row">
            <button class="me-1 primaryButton" kendoButton [disabled]="this.orderNotFromCustomerPortal() || !this.portalOrder"
                (click)="openOriginalOrder()">{{"order.openOriginalOrder" | translate}}</button>
            <ntw-loading-button class="mx-1" buttonClass="primaryButton" (click)="sendPackingSlip()"
                [disabled]="!canCreateSendPackingSlipRequest()" [loading]="isSubmittingPrintPackingSlipRequest"
                [text]="'order.sendPackingSlip' | translate">
            </ntw-loading-button>
        </div>
    </div>
    <div class="row my-3">    
        <div class="col col-lg-10 pb-2">
            <label class="headerBig">{{ 'order.orderLines' | translate }}</label>
        </div>
    </div>
    <div>
        <ntw-grid-template [currentAccount]="this.currentAccount" [columnSettings]="this.columnSettings"
            idFieldName="LineNum" [selectableSettings]="{enabled: false}" [entities]="this.entities"
            [dataGridView]="this.dataGridView" [summaries]="this.summaries" [loading]="this.loading"
            [dataStateChange]="this.dataStateChange" [kendoGridState]="this.kendoGridState"
            [showCheckBoxColumn]="false" [showMenu]="false" [drillDownEnabled]="false">
        </ntw-grid-template>
    </div>
</ng-container>