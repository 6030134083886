import { ColumnSettings } from "src/app/shared/models/ui/column-settings";

export const invoiceHeaderColumns: ColumnSettings[] = [
    {
        field: "InvoiceNumber", minResizableWidth: 110, width: 110,
        title: "invoice.InvoiceNumber"
    },
    {
        field: "InvoiceDate", filter: "date", format: "dd/MM/yyyy", minResizableWidth: 110,
        width: 110, title: "invoice.InvoiceDate"
    },
    {
        field: "DueDate", filter: "date", format: "dd/MM/yyyy", minResizableWidth: 80, width: 90,
        title: "invoice.DueDate"
    },
    {
        field: "InvoiceCurrency", minResizableWidth: 90, width: 90, translate: true,
        title: "invoice.InvoiceCurrency", sortable: false, filterable: false
    },
    {
        field: "TotalLines", filter: "numeric", minResizableWidth: 90, width: 90,
        format: "{0:n0}", title: "invoice.TotalLines", sortable: false, filterable: false
    },
    {
        field: "TotalAmount", filter: "numeric", minResizableWidth: 100, width: 100,
        format: "{0:n2}", title: "invoice.TotalAmount", sortable: false, filterable: false
    },
    {
        field: "TotalVAT", filter: "numeric", minResizableWidth: 100, width: 100,
        format: "{0:n2}", title: "invoice.TotalVAT", sortable: false, filterable: false
    },
    {
        field: "TotalAmountInclVAT", filter: "numeric", minResizableWidth: 100, width: 100,
        format: "{0:n2}", title: "invoice.TotalAmountInclVAT", sortable: false, filterable: false
    },
    {
        field: "PaymentStatus", minResizableWidth: 90, width: 90, title: "invoice.PaymentStatus",
        sortable: false, filterable: false, translate: true,
    },
];
