import { OrderHeader } from "../orders/order-header";

export class PrintPackingSlipRequest {
    companyId: string;
    documentNum: string;
    custAccount: string;
    emailTo: string;

    constructor(order: OrderHeader, email: string) {
        this.companyId = order.bkCompanyKey;
        this.documentNum = order.bkSalesOrderHeader;
        this.custAccount = order.bkCustomerDeliveryKey;
        this.emailTo = email;
    }
}

export interface PrintPackingSlipResponse {
    errorMsg: string;
    resultRecId: number;
    resultStr: string;
    resultXML: string;
    isOk: boolean;
}
