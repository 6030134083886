import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { concatMap } from 'rxjs';
import { invoicesDetailsFormName, invoicesDetailsLineColumnsFormName } from 'src/app/shared/constants/form-names.consts';
import { CrmAccount } from 'src/app/shared/models/account';
import { CrmContact } from 'src/app/shared/models/contact';
import { CosmosCustomer } from 'src/app/shared/models/customer';
import { FormConfiguration } from 'src/app/shared/models/form-configuration';
import { InvoiceHeader } from 'src/app/shared/models/invoices/invoice-header';
import { InvoiceLine } from 'src/app/shared/models/invoices/invoice-line';
import { KendoGridBase } from 'src/app/shared/models/kendo-grid-base';
import { ColumnSettings } from 'src/app/shared/models/ui/column-settings';
import { TitleValue } from 'src/app/shared/models/ui/title-value';
import { SecurityService } from 'src/app/shared/security/security.service';
import { ICustomerService } from 'src/app/shared/services/customer/customer.service.interface';
import { KendoAlertService } from 'src/app/shared/services/kendo-alerts.service';
import { IPrintService } from 'src/app/shared/services/print/print.service.interface';
import { CompanyAdminConfigurationStore } from 'src/app/shared/stores/company-admin-configuration.store';
import { CurrentUserContactStore } from 'src/app/shared/stores/current-user-contact.store';
import { Utils } from 'src/app/shared/utils';
import { IInvoiceService } from '../services/invoice.service.interface';
import { invoiceLinesColumns } from './invoice-line-columns';
import { FilterAndSortDescriptionService } from 'src/app/shared/services/filter-sort-description.service';

@Component({
  selector: 'ntw-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss', './../../shared/styles/button.scss']
})
export class InvoiceDetailComponent extends KendoGridBase<InvoiceLine> implements OnInit {
  invoiceNumber: string;
  selectedInvoiceHeader: InvoiceHeader;
  isSubmittingPrintInvoiceRequest = false;
  formConfig: FormConfiguration;
  columnsFormConfig: FormConfiguration;

  currentAccount: CrmAccount;
  currentContact?: CrmContact;
  currentCustomer?: CosmosCustomer;

  public firstRow: TitleValue[] = [];
  public secondRow: TitleValue[] = [];
  public thirdRow: TitleValue[] = [];

  public columnSettings: ColumnSettings[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private companyAdminConfigurationStore: CompanyAdminConfigurationStore,
    private currentUserContactStore: CurrentUserContactStore,
    private customerService: ICustomerService,
    private filterSortService: FilterAndSortDescriptionService,
    private invoiceService: IInvoiceService,
    private kendoAlertService: KendoAlertService,
    private printService: IPrintService,
    private securityService: SecurityService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.currentAccount = this.securityService.getCurrentAccountObject();
    this.currentUserContactStore.get().subscribe(contact => this.currentContact = contact);
    this.customerService.getCustomerByCode(this.currentAccount.ntw_axcode).subscribe(customer => this.currentCustomer = customer);

    this.companyAdminConfigurationStore.getFormConfiguration(invoicesDetailsFormName).pipe(concatMap(formConfig => {
      this.formConfig = formConfig;
      return this.companyAdminConfigurationStore.getFormConfiguration(invoicesDetailsLineColumnsFormName);
    }), concatMap(columnsFormConfig => {
      this.columnsFormConfig = columnsFormConfig;
      return this.activatedRoute.paramMap;
    })).subscribe({
      next: params => {
        this.invoiceNumber = params.get("id");
        this.columnSettings = FormConfiguration.applyToColumnSettings(this.columnsFormConfig, [...invoiceLinesColumns]);
        this.getEntitiesForAccount();
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
      }
    });
  }

  getEntitiesForAccount() {
    this.invoiceService.getInvoiceDetail(this.invoiceNumber, this.currentAccount.ntw_axcode).subscribe({
      next: result => {
        if (!result) {
          return;
        }

        if (result['value'] && result['value'][0]) {
          result = result['value'][0] as InvoiceHeader;
        }
        else if (result[0]) {
          result = result[0] as InvoiceHeader;
        }
        this.selectedInvoiceHeader = result;
        this.entities = result.InvoiceLines;
        this.setDefaultSort();
        this.generateGridDataView();
        this.fillHeaderDetails();
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
        this.loading = false;
      }
    });
  }

  private setDefaultSort() {
    const orderNumberSort = this.filterSortService.createSortDescriptor("SalesOrderNum", "asc");
    const lineNumSort = this.filterSortService.createSortDescriptor("SalesLine", "asc");
    this.kendoGridState.sort = [orderNumberSort, lineNumSort];
  }

  public fillHeaderDetails() {
    this.firstRow = [
      {
        field: "InvoiceNumber",
        title: "invoice.InvoiceNumber",
        value: this.selectedInvoiceHeader.InvoiceNumber,
        subtitle: ''
      },

      {
        field: "InvoiceDate",
        title: "invoice.InvoiceDate",
        value: Utils.FormatDate(this.selectedInvoiceHeader.InvoiceDate),
        subtitle: ''
      },
      {
        field: "DueDate",
        title: "invoice.DueDate",
        value: Utils.FormatDate(this.selectedInvoiceHeader.DueDate),
        subtitle: ''
      }
    ];

    this.secondRow = [
      {
        field: "InvoiceCurrency",
        title: "invoice.InvoiceCurrency",
        value: this.selectedInvoiceHeader.InvoiceCurrency,
        subtitle: ''
      },
      {
        field: "PaymentStatus",
        title: "invoice.PaymentStatus",
        value: this.selectedInvoiceHeader.PaymentStatus,
        subtitle: ''
      },
      {
        field: "TotalLines",
        title: "invoice.TotalLines",
        value: Utils.FormatNumber(this.selectedInvoiceHeader.TotalLines),
        subtitle: ''
      },
    ];

    this.thirdRow = [
      {
        field: "TotalAmount",
        title: this.AddCurrencyCode(this.translateService.instant("invoice.TotalAmount")),
        value: Utils.FormatNumber(this.selectedInvoiceHeader.TotalAmount, 2),
        subtitle: ''
      },
      {
        field: "TotalVAT",
        title: this.AddCurrencyCode(this.translateService.instant("invoice.TotalVAT")),
        value: Utils.FormatNumber(this.selectedInvoiceHeader.TotalVAT, 2),
        subtitle: ''
      },
      {
        field: "TotalAmountInclVAT",
        title: this.AddCurrencyCode(this.translateService.instant("invoice.TotalAmountInclVAT")),
        value: Utils.FormatNumber(this.selectedInvoiceHeader.TotalAmountInclVAT, 2),
        subtitle: ''
      },
    ];

    this.firstRow = FormConfiguration.applyToTitleValues(this.formConfig, this.firstRow);
    this.secondRow = FormConfiguration.applyToTitleValues(this.formConfig, this.secondRow);
    this.thirdRow = FormConfiguration.applyToTitleValues(this.formConfig, this.thirdRow);
  }

  AddCurrencyCode(value: string) {
    return `${value} (${this.selectedInvoiceHeader.InvoiceCurrency})`
  }

  canCreatePrintInvoiceRequest() {
    return this.selectedInvoiceHeader
      && this.selectedInvoiceHeader.InvoiceNumber
      && this.selectedInvoiceHeader.bkInvoiceAccountKey
      && this.selectedInvoiceHeader.InvoiceDate
      && this.currentCustomer
      && this.currentCustomer.TikkurilaCompanyRegisterCode
      && this.currentContact
      && this.currentContact.emailaddress1;
  }

  sendInvoice() {
    if (!this.canCreatePrintInvoiceRequest() || this.isSubmittingPrintInvoiceRequest) {
      return;
    }

    this.isSubmittingPrintInvoiceRequest = true;

    this.printService.printInvoice(this.selectedInvoiceHeader, this.currentContact.emailaddress1, this.currentCustomer)
      .subscribe({
        next: response => {
          if (!response.isOk) {
            this.kendoAlertService.showErrorAlert(response.errorMsg);
          }
          else {
            this.kendoAlertService.showSuccessAlert("alerts.printInvoiceRequestSubmittedSuccessfully", true);
          }
          this.isSubmittingPrintInvoiceRequest = false;
        },
        error: error => {
          this.kendoAlertService.showErrorAlert(error);
          this.isSubmittingPrintInvoiceRequest = false;
        }
      })
  }
}
