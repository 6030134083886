import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { CompanyAdminConfiguration } from '../models/company-admin-configuration';
import { SecurityService } from '../security/security.service';
import { ICustomerService } from '../services/customer/customer.service.interface';
import { CompanyAdminConfigurationStore } from '../stores/company-admin-configuration.store';

export const companyAdminConfigurationResolver: ResolveFn<CompanyAdminConfiguration> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  securityService: SecurityService = inject(SecurityService),
  customerService: ICustomerService = inject(ICustomerService),
  companyAdminConfigurationStore: CompanyAdminConfigurationStore = inject(CompanyAdminConfigurationStore)
) => {
  const crmAccount = securityService.getCurrentAccountObject();
  return customerService.getCustomerByCode(crmAccount.ntw_axcode).pipe(concatMap(customer => {
    if (customer) {
      return companyAdminConfigurationStore.load(customer.TikkurilaCompanyRegisterCode);
    }
    else {
      return of(undefined);
    }
  }), catchError(error => {
    console.error(error);
    return of(undefined);
  }));
}
