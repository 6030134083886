import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyAdminConfiguration } from '../../models/company-admin-configuration';

@Injectable({
  providedIn: 'root'
})
export abstract class ICompanyAdminConfigurationService {
  public abstract get(bkCompanyKey: string): Observable<CompanyAdminConfiguration>;
}
