import { CosmosCustomer } from "../customer";

export class PrintAccountStatementRequest {
    companyId: string;
    custAccount: string;
    emailTo: string;

    constructor(email: string, customer: CosmosCustomer) {
        this.companyId = customer.TikkurilaCompanyRegisterCode;
        this.custAccount = customer.bkCustomerKey;
        this.emailTo = email;
    }
}

export interface PrintAccountStatementResponse {
    errorMsg: string;
    resultRecId: number;
    resultStr: string;
    resultXML: string;
    isOk: boolean;
}
