import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { ContextGuardService } from './context-guard.service';

export const contextGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  _state,
  contextGuardService: ContextGuardService = inject(ContextGuardService)
): Observable<boolean> => {
  return contextGuardService.canActivate(route);
}
