import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewOrderAddress } from 'src/app/shared/models/address/address';
import { Warehouse } from 'src/app/shared/models/warehouse';
import { IAddressService } from 'src/app/shared/services/address/address.service.interface';
import { WarehouseService } from 'src/app/shared/services/warehouse/warehouse.service';
import { environment } from 'src/environments/environment';
import { IOrderProcessService } from './order.process.service.interface';

@Injectable({
  providedIn: 'root'
})
export class OrderProcessService implements IOrderProcessService {
  baseUrl: string;
  accountSelector = "warehouseName";

  constructor(
    private addressService: IAddressService,
    private warehouseService: WarehouseService
  ) {
    this.baseUrl = environment.apiBaseUrl;
  }
  public getAddresses(accountKey: string): Observable<NewOrderAddress[]> {
    return this.addressService.getAddresses(accountKey);
  }
  public getWarehouses(): Observable<Warehouse[]> {
    return this.warehouseService.get();
  }

}
