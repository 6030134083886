import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CosmosAddress } from "../../models/address/address";


@Injectable({
    providedIn: 'root'
})

export abstract class IAddressService {
    public abstract getAddressById(addressId: string): Observable<CosmosAddress>;
    public abstract getAddresses(customerbkObjectKey: string): Observable<CosmosAddress[]>;
    public abstract getPrimaryAddressByCustomerKey(bkCustomerKey: string): Observable<CosmosAddress>;
    public abstract getInvoiceAddressByCustomerKey(bkCustomerKey: string): Observable<CosmosAddress>;
}
