import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TreeViewComponent } from '@progress/kendo-angular-treeview';
import { IAccountService } from 'src/app/account/services/account.service.interface';
import { KendoIconSets } from 'src/app/shared/constants/kendo-icon-sets';
import { CrmAccount } from 'src/app/shared/models/account';
import { SecurityService } from 'src/app/shared/security/security.service';
import { KendoAlertService } from 'src/app/shared/services/kendo-alerts.service';
import { GridPersistService } from 'src/app/shared/services/ui/grid-persist.service';

@Component({
  selector: 'ntw-account-context-chooser',
  templateUrl: './account-context-chooser.component.html',
  styleUrls: ['./account-context-chooser.component.scss']
})

export class AccountContextChooserComponent implements OnInit {
  icons = KendoIconSets;
  @ViewChild('treeView', { static: false }) treeView: TreeViewComponent;
  selectedAccount: CrmAccount;
  contextData: CrmAccount[];
  selectedKeys = [];
  expandedKeys = [];
  isAccountSelectionVisible = false;
  loading = true;
  areAccountsLoading = true;

  @ViewChild('contextTreeToggleButton') public toggleButton: ElementRef;

  @HostListener('keydown', ['$event'])
  public keydown(event): void {
    if (event.keyCode === 27) {
      this.toggle(false);
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event): void {
    if (!this.contains(event.target)) {
      this.toggle(false);
    }
  }

  constructor(private accountService: IAccountService,
    private router: Router,
    public securityService: SecurityService,
    private kendoAlertService: KendoAlertService,
    private gridPersistService: GridPersistService) {
  }

  ngOnInit(): void {
    this.accountService.getAccounts().subscribe({
      next: accounts => {
        this.contextData = this.setupAccountTreeNode(accounts);
        this.areAccountsLoading = false;
        this.loading = false;
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
        this.areAccountsLoading = false;
        this.loading = false;
      }
    });
    this.securityService.getCurrentAccount().subscribe({
      next: currentAccount => {
        this.selectedAccount = currentAccount;
      }
    });
  }

  public handleSelection(event): void {
    this.close();
    this.gridPersistService.ResetGridState();
    this.router.navigate(['account', event.dataItem.accountid]);
  }

  public expandAccountSelection(): void {
    this.isAccountSelectionVisible = !this.isAccountSelectionVisible;
  }

  public toggle(show?: boolean): void {
    this.isAccountSelectionVisible = show !== undefined ? show : !this.isAccountSelectionVisible;
  }

  public close(): void {
    this.isAccountSelectionVisible = false;
  }

  private contains(target): boolean {
    return this.toggleButton?.nativeElement.contains(target) ||
      (this.treeView ? this.treeView.element?.nativeElement?.contains(target) : false);
  }

  private setupAccountTreeNode(accounts: CrmAccount[]): CrmAccount[] {
    accounts = accounts.filter(x => x.ntw_axcode);
    const ids = accounts.map(x => x.accountid);
    accounts.forEach(x => {
      if (!ids.includes(x._parentaccountid_value)) {
        x._parentaccountid_value = null;
      }
    })
    return accounts;
  }
}
