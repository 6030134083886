import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserSettings } from '../../models/user-settings';

@Injectable({
    providedIn: 'root'
})

export abstract class IUserSettingsService {
    public abstract get(userb2cId: string): Observable<UserSettings | undefined>
    public abstract create(userSettings: UserSettings): Observable<unknown>
    public abstract update(userSettings: UserSettings): Observable<unknown>
}
