import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IOrderService } from 'src/app/order/services/order.service.interface';
import { KendoUtils } from 'src/app/shared/kendo-utils';
import { InvoiceHeader } from 'src/app/shared/models/invoices/invoice-header';
import { ODataResponse } from 'src/app/shared/models/odata-response';
import { environment } from 'src/environments/environment';
import { IInvoiceService } from './invoice.service.interface';

@Injectable()
export class InvoiceService implements IInvoiceService {
  baseUrl: string;
  headerSelector = "Id,InvoiceNumber,bkInvoiceAccountKey,InvoiceDate,DueDate,InvoiceCurrency,TotalLines,TotalVAT,TotalAmountInclVAT,TotalAmount,ClosedDate";
  invoiceLineSelector = "InvoiceHeaderId";
  detailedInvoiceLineSelector = "InvoiceHeaderId,bkSalesOrderKey,SalesLine,bkProductKey,ItemDescription,SalesQty,"
    + "LineAmount,SalesL,SalesGrossKG,SalesNetKG,CustomerReference,SalesOrderNum,CustomerRequisition,bkCompanyKey,TLAUnitPriceAfterDisc";

  constructor(private httpClient: HttpClient, private orderService: IOrderService) {
    this.baseUrl = environment.apiBaseUrl + `invoiceheaders`;
  }

  getInvoicesWithGridState(gridState: State, additionalFilters?: CompositeFilterDescriptor): Observable<ODataResponse<InvoiceHeader[]>> {
    const url = this.baseUrl
      + `?$select=${this.headerSelector}`
      + `&$expand=InvoiceLines($select=${this.invoiceLineSelector})`
      + `&${KendoUtils.toODataString(gridState, additionalFilters)}`
      + `&$count=true`;

    return this.httpClient.get<ODataResponse<InvoiceHeader[]>>(url).pipe(
      map(response => {
        if (response && response.value) {
          response.value.forEach(x => {
            this.calculateFields(x);
          });
        }
        return new ODataResponse<InvoiceHeader[]>(response)
      }));
  }


  getInvoiceDetail(invoiceNumber: string, bkInvoiceAccountKey: string): Observable<InvoiceHeader> {
    const url = this.baseUrl
      + `?$select=${this.headerSelector}`
      + `&$filter=InvoiceNumber eq '${invoiceNumber}' and bkInvoiceAccountKey eq '${bkInvoiceAccountKey}'`
      + `&$expand=InvoiceLines($select=${this.detailedInvoiceLineSelector})`
      + "&$top=1";

    return this.httpClient.get<ODataResponse<InvoiceHeader[]>>(url).pipe(
      map(response => {
        if (response && response.value && response.value[0]) {
          this.setSalesAgentReference(response.value[0], bkInvoiceAccountKey);
          this.calculateFields(response.value[0]);
          return response.value[0];
        }
        return null;
      }));
  }

  setSalesAgentReference(header: InvoiceHeader, bkInvoiceAccountKey: string)
  {
    // get all orders from invoice lines and remove duplicates
    const orders = header.InvoiceLines?.map(x => x.bkSalesOrderKey)
      .filter(x => x != null)
      .filter((value, index, self) => self.indexOf(value) === index);

    if (!orders || orders.length == 0) {
      return;
    }

    // for each order get the agent reference and set it on the invoice lines
    orders.forEach(order => {
      this.orderService.getOrderAgentReference(`${order}_0`, bkInvoiceAccountKey).subscribe(x => {
        header.InvoiceLines?.filter(y => y.bkSalesOrderKey == order).forEach(y => {
          y.SalesAgentReference = x;
        });
      });
    });
  }

  calculateFields(header: InvoiceHeader) {
    this.calculatePaymentStatus(header);
    this.calculateTotalLines(header);
    this.setProductKeyWithoutCompanyKey(header);
  }

  private calculatePaymentStatus(header: InvoiceHeader) {
    if (!header.ClosedDate || header.ClosedDate < header.InvoiceDate) {
      header.PaymentStatus = "invoice.PaymentStatuses.NotPaid";
    }
    else {
      header.PaymentStatus = "invoice.PaymentStatuses.Paid";
    }
  }

  private calculateTotalLines(header: InvoiceHeader) {
    if (header.InvoiceLines?.length == 0) {
      return;
    }
    header.InvoiceLines.sort((x, y) => x.SalesLine > y.SalesLine ? 1 : -1);
    header.TotalLines = header.InvoiceLines?.length;
  }


  private setProductKeyWithoutCompanyKey(header: InvoiceHeader) {
    if (!header.InvoiceLines) {
      return;
    }
    header.InvoiceLines.forEach(line => {
      if (!line.bkCompanyKey || !line.bkProductKey) {
        return;
      }
      line.bkProductKeyNoCode = line.bkProductKey.substring(0, line.bkProductKey.indexOf(`_${line.bkCompanyKey}`));
    })
  }

}
