import { ColumnSettings } from "src/app/shared/models/ui/column-settings";

export const PortalOrderColumns: ColumnSettings[] = [
    {
        field: "createdOn", minResizableWidth: 110, width: 110, filter: "date", format: "dd/MM/yyyy",
        title: "portalOrder.orderDate"
    },
    {
        field: "customerRequisition", minResizableWidth: 130, width: 130,
        title: "portalOrder.customerRequisition"
    },
    {
        field: "customerReference", minResizableWidth: 110, width: 110,
        title: "portalOrder.customerReference"
    },
    {
        field: "orderNumber", minResizableWidth: 110, width: 110,
        title: "portalOrder.orderNumber"
    },
    {
        field: "totalProducts", minResizableWidth: 90, width: 90, filter: "numeric",
        title: "portalOrder.totalProducts", sortable: false, filterable: false
    },
    {
        field: "totalValue", minResizableWidth: 100, width: 100, filter: "numeric", format: "{0:n2}",
        title: "portalOrder.totalValue", sortable: false, filterable: false
    },
    {
        field: "totalVat", minResizableWidth: 90, width: 90, filter: "numeric", format: "{0:n2}",
        title: "portalOrder.totalVat", sortable: false, filterable: false
    },
    {
        field: "totalValueWithVat", minResizableWidth: 100, width: 100, filter: "numeric", format: "{0:n2}",
        title: "portalOrder.totalValueWithVat", sortable: false, filterable: false
    },
    {
        field: "orderSubmissionStatusName", minResizableWidth: 90, width: 90,
        title: "portalOrder.orderSubmissionStatus", sortable: false, filterable: false
    },
    {
        field: "warehouse.warehouseName", minResizableWidth: 110, width: 110,
        title: "portalOrder.orderWarehouse"
    },
    {
        field: "isWarehousePickUpPoint", minResizableWidth: 80, width: 80, filter: 'boolean',
        title: "portalOrder.isWarehousePickUpPoint"
    },
    {
        field: "deliveryAddress.street", minResizableWidth: 110, width: 120,
        title: "portalOrder.deliveryAddressDetails.street1"
    },
    {
        field: "deliveryAddress.city", minResizableWidth: 110, width: 110,
        title: "portalOrder.deliveryAddressDetails.city"
    },
    {
        field: "deliveryAddress.zipCode", minResizableWidth: 110, width: 110,
        title: "portalOrder.deliveryAddressDetails.zipCode"
    },
    {
        field: "backOrdersPossible", minResizableWidth: 60, width: 60, filter: 'boolean',
        title: "portalOrder.backOrdersPossible"
    },
    // {
    //     field: "requestedDeliveryDate", minResizableWidth: 110, width: 110, filter: "date", format: "dd/MM/yyyy",
    //     title: "portalOrder.requestedDeliveryDate"
    // }
];
