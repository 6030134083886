import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AccountDetailComponent } from './account/account-detail/account-detail.component';
import { CustomRouteReuseStrategy } from './app-routing.reuse-strategy';
import { InvoiceDetailComponent } from './invoice/invoice-detail/invoice-detail.component';
import { InvoiceListComponent } from './invoice/invoice-list/invoice-list.component';
import { OrderProcessComponent } from './order-process/order-process.component';
import { OrderDetailComponent } from './order/order-detail/order-detail.component';
import { OrderListComponent } from './order/order-list/order-list.component';
import { PortalOrderDetailComponent } from './portal-order/portal-order-detail/portal-order-detail.component';
import { PortalOrderListComponent } from './portal-order/portal-order-list/portal-order-list.component';
import { SelectAccountComponent } from './select-account/select-account.component';
import { companyAdminConfigurationResolver } from './shared/router-resolvers/company-admin-configuration.resolver';
import { canDeactivateGuard } from './shared/security/guards/can-deactivate.guard';
import { contextGuard } from './shared/security/guards/context.guard';
import { createOrdersGuard } from "./shared/security/guards/create-orders.guard";
import { userRolesGuard } from './shared/security/guards/user-roles.guard';
import { viewInvoicesGuard } from "./shared/security/guards/view-invoices.guard";
import { viewOrdersGuard } from "./shared/security/guards/view-orders.guard";
import { UserWithoutRolesComponent } from './user-without-roles/user-without-roles.component';

const routes: Routes = [
  {
    path: 'account/:accountid',
    component: AccountDetailComponent,
    canActivate: [MsalGuard, contextGuard, userRolesGuard],
    resolve: { companyAdminConfiguration: companyAdminConfigurationResolver }
  },
  {
    path: 'account/:accountid/draftorder',
    component: OrderProcessComponent,
    //createOrdersGuard contains the contextGuard
    canActivate: [MsalGuard, userRolesGuard, createOrdersGuard],
    canDeactivate: [canDeactivateGuard],
    resolve: { companyAdminConfiguration: companyAdminConfigurationResolver }
  },
  {
    path: 'account/:accountid/draftorder/:id',
    component: OrderProcessComponent,
    //createOrdersGuard contains the contextGuard
    canActivate: [MsalGuard, userRolesGuard, createOrdersGuard],
    canDeactivate: [canDeactivateGuard],
    resolve: { companyAdminConfiguration: companyAdminConfigurationResolver }
  },
  {
    path: 'account/:accountid/openorders',
    component: PortalOrderListComponent,
    canActivate: [MsalGuard, contextGuard, userRolesGuard, viewOrdersGuard],
    resolve: { companyAdminConfiguration: companyAdminConfigurationResolver }
  },
  {
    path: 'account/:accountid/openorders/:id',
    component: PortalOrderDetailComponent,
    canActivate: [MsalGuard, contextGuard, userRolesGuard, viewOrdersGuard],
    resolve: { companyAdminConfiguration: companyAdminConfigurationResolver }
  },
  {
    path: 'account/:accountid/orders',
    component: OrderListComponent,
    canActivate: [MsalGuard, contextGuard, userRolesGuard, viewOrdersGuard],
    resolve: { companyAdminConfiguration: companyAdminConfigurationResolver }
  },
  {
    path: 'account/:accountid/orders/:id',
    component: OrderDetailComponent,
    canActivate: [MsalGuard, contextGuard, userRolesGuard, viewOrdersGuard],
    resolve: { companyAdminConfiguration: companyAdminConfigurationResolver }
  },
  {
    path: 'account/:accountid/invoices',
    component: InvoiceListComponent,
    canActivate: [MsalGuard, contextGuard, userRolesGuard, viewInvoicesGuard],
    resolve: { companyAdminConfiguration: companyAdminConfigurationResolver }
  },
  {
    path: 'account/:accountid/invoices/:id',
    component: InvoiceDetailComponent,
    canActivate: [MsalGuard, contextGuard, userRolesGuard, viewInvoicesGuard],
    resolve: { companyAdminConfiguration: companyAdminConfigurationResolver }
  },
  // {
  //   path: 'account/:accountid/products',
  //   component: ProductListComponent,
  //   canActivate: [MsalGuard, contextGuard, userRolesGuard],
  //   resolve: { companyAdminConfiguration: companyAdminConfigurationResolver }
  // },
  // {
  //   path: 'account/:accountid/products/:id',
  //   component: ProductDetailComponent,
  //   canActivate: [MsalGuard, contextGuard, userRolesGuard],
  //   resolve: { companyAdminConfiguration: companyAdminConfigurationResolver }
  // },
  // {
  //   path: 'account/:accountid/tasks',
  //   component: TaskListComponent,
  //   canActivate: [MsalGuard, contextGuard, userRolesGuard],
  //   resolve: { companyAdminConfiguration: companyAdminConfigurationResolver }
  // },
  // {
  //   path: 'account/:accountid/tasks/:id',
  //   component: TaskDetailsComponent,
  //   canActivate: [MsalGuard, contextGuard, userRolesGuard],
  //   resolve: { companyAdminConfiguration: companyAdminConfigurationResolver }
  // },
  {
    path: 'security',
    component: UserWithoutRolesComponent,
    canActivate: [MsalGuard]
  },
  {
    path: '',
    component: SelectAccountComponent,
    canActivate: [MsalGuard, userRolesGuard]
  },
  {
    path: '**', component: SelectAccountComponent,
    canActivate: [MsalGuard, userRolesGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }
  ]
})
export class AppRoutingModule { }
