import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MaintenanceConfiguration, MaintenanceType } from '../../models/maintenance-configuration';
import { CompanyAdminConfigurationStore } from '../../stores/company-admin-configuration.store';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  public maintenanceConfiguration: BehaviorSubject<MaintenanceConfiguration> = new BehaviorSubject<MaintenanceConfiguration>(undefined);
  public isMaintenanceActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAnnouncementActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private companyAdminConfigurationStore: CompanyAdminConfigurationStore) {
    this.companyAdminConfigurationStore.get().subscribe({
      next: companyAdminConfiguration => {
        if (companyAdminConfiguration?.maintenanceConfiguration?.isMaintenanceMode) {
          const maintenanceConfiguration = companyAdminConfiguration.maintenanceConfiguration;
          this.maintenanceConfiguration.next(maintenanceConfiguration);

          const isMaintenance = this.getIsMaintenanceActive(maintenanceConfiguration);
          const isAnnouncement = this.getIsAnnouncementActive(maintenanceConfiguration);

          this.updateSubjects(isMaintenance, isAnnouncement);
        }
      }
    });
  }

  private updateSubjects(isMaintenance: boolean, isAnnouncement: boolean): void {
    if (isMaintenance) {
      if (this.isMaintenanceActive.value !== true) {
        this.isMaintenanceActive.next(true);
      }
      if (this.isAnnouncementActive.value !== false) {
        this.isAnnouncementActive.next(false);
      }
    }
    else if (isAnnouncement) {
      if (this.isAnnouncementActive.value !== true) {
        this.isAnnouncementActive.next(true);
      }
      if (this.isMaintenanceActive.value !== false) {
        this.isMaintenanceActive.next(false);
      }

      const now = new Date();
      const maintenanceConfiguration = this.maintenanceConfiguration.value;

      const timeToAnnouncement = maintenanceConfiguration.announcementStartTime.getTime() - now.getTime();

      setTimeout(() => {
        this.updateSubjects(this.getIsMaintenanceActive(maintenanceConfiguration), this.getIsAnnouncementActive(maintenanceConfiguration));
      }, timeToAnnouncement);
    }
  }

  private getIsMaintenanceActive(maintenanceConfiguration: MaintenanceConfiguration): boolean {
    if (!maintenanceConfiguration || !maintenanceConfiguration.isMaintenanceMode) {
      return false;
    }

    if (maintenanceConfiguration.maintenanceType === MaintenanceType.Manual) {
      return true;
    }

    if (maintenanceConfiguration.maintenanceType === MaintenanceType.Scheduled) {
      const now = new Date();

      if (maintenanceConfiguration.maintenanceStartTime && maintenanceConfiguration.maintenanceEndTime) {
        return now >= maintenanceConfiguration.maintenanceStartTime && now <= maintenanceConfiguration.maintenanceEndTime;
      }
      else if (maintenanceConfiguration.maintenanceStartTime) {
        return now >= maintenanceConfiguration.maintenanceStartTime;
      }
      else if (maintenanceConfiguration.maintenanceEndTime) {
        return now <= maintenanceConfiguration.maintenanceEndTime;
      }
    }
    return false;
  }

  private getIsAnnouncementActive(maintenanceConfiguration: MaintenanceConfiguration): boolean {
    if (!maintenanceConfiguration || !maintenanceConfiguration.isAnnouncement || !maintenanceConfiguration.announcementStartTime || !maintenanceConfiguration.maintenanceStartTime) {
      return false;
    }

    const now = new Date();
    return now >= maintenanceConfiguration.announcementStartTime && now < maintenanceConfiguration.maintenanceStartTime;
  }
}
