import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CosmosCustomer } from "../../models/customer";
import { InvoiceHeader } from "../../models/invoices/invoice-header";
import { OrderHeader } from "../../models/orders/order-header";
import { PrintAccountStatementResponse } from "../../models/print/account-statement";
import { PrintInvoiceResponse } from "../../models/print/invoice";
import { PrintPackingSlipResponse } from "../../models/print/packingslip";

@Injectable({
    providedIn: 'root'
})

export abstract class IPrintService {
    public abstract printAccountStatement(email: string, customer: CosmosCustomer): Observable<PrintAccountStatementResponse>
    public abstract printPackingSlip(order: OrderHeader, email: string): Observable<PrintPackingSlipResponse>;
    public abstract printInvoice(invoice: InvoiceHeader, email: string, customer: CosmosCustomer): Observable<PrintInvoiceResponse>
}
