import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CompositeFilterDescriptor, FilterDescriptor, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KendoUtils } from 'src/app/shared/kendo-utils';
import { CompanyAdminConfiguration } from 'src/app/shared/models/company-admin-configuration';
import { ODataResponse } from 'src/app/shared/models/odata-response';
import { OrderHeader } from 'src/app/shared/models/orders/order-header';
import { CompanyAdminConfigurationStore } from 'src/app/shared/stores/company-admin-configuration.store';
import { environment } from 'src/environments/environment';
import { IOrderService } from './order.service.interface';

const salesStatusStrings = {
  Shipped: "Shipped",
  Delivered: "Delivered"
}

@Injectable()
export class OrderService implements IOrderService {
  baseUrl: string;
  headerSelectorArray = ["Id", "bkSalesOrderHeader", "OrderCreationTime", "bkCustomerDeliveryKey", "bkInvoiceAccountKey", "bkInvoicePostalAddressKey",
    "bkCompanyKey", "bkRequestedShipmentDateKey", "Deadline", "VatNum", "SalesStatus",
    "bkOrderDateKey", "CustomerReference", "CustomerRequisition", "PaymMode", "CustomerPriceGroup",
    "DeliveryPostalAddressRecId", "SalesID", "SalesOriginId", "DaxIntegration"];
  headerSelector = this.headerSelectorArray.join();
  headerLinesExpand = "&$expand=OrderLines($select=UnitNetPriceAfterDiscount,LineNum,bkCurrencyKey,LineAmount, OrderedQty)"

  orderLineSelector = "UnitNetPriceAfterDiscount,OrderedQty,LineNum,LineAmount,LineAmountEur,LinePercent,DeliveryPostalAddressRecId,PhysicalReserved,DeliveryReminderQty,OnOrder,Invoiced,Shipped,Picked"
    + ",bkCurrencyKey,bkProductKey,bkTaxItemGroupKey,SalesOrderTypeName,SalesUnit,bkRequestedShipmentDateKey,bkConfirmedShipmentDateKey,BasePaintItemName,SalesStatusCode,SalesStatus,DeliveryMode"

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private companyAdminConfigurationStore: CompanyAdminConfigurationStore) {
    this.baseUrl = environment.apiBaseUrl;
  }

  getOrdersWithGridState(bkInvoiceAccountKey: string, gridState: State, additionalFilters?: CompositeFilterDescriptor): Observable<ODataResponse<OrderHeader[]>> {
    this.applyAccountFilters(gridState, bkInvoiceAccountKey);

    const url = this.baseUrl + `orderheaders`
      + `?$select=${this.headerSelector}`
      + this.headerLinesExpand
      + `&${KendoUtils.toODataString(gridState, additionalFilters)}`
      + `&$count=true`;
    return this.httpClient.get<ODataResponse<OrderHeader[]>>(url).pipe(
      map(response => {
        if (response && response.value) {
          response.value.forEach(x => {
            this.calculateFieldsFromLines(x);
          });
        }
        response.value.forEach(x => this.setSalesStatusName(x));
        return new ODataResponse<OrderHeader[]>(response)
      }));
  }

  private applyAccountFilters(gridState: State, customerAccountKey: string) {
    let filterApplied = false;
    const currentFilters = gridState.filter.filters as CompositeFilterDescriptor[];
    const ordersForAccountFilter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [{
        value: customerAccountKey,
        field: "bkInvoiceAccountKey",
        operator: "eq",
      } as FilterDescriptor,
      {
        logic: "and",
        filters: [
          {
            value: customerAccountKey,
            field: "bkCustomerDeliveryKey",
            operator: "eq"
          } as FilterDescriptor,
          {
            value: customerAccountKey,
            field: "bkInvoiceAccountKey",
            operator: "neq"
          } as FilterDescriptor,
        ]
      }]
    };

    currentFilters.forEach(filter => {
      if (filter.filters) {
        filter.filters.forEach(nestedFilter => {
          const filterDescription = (nestedFilter as FilterDescriptor);
          if (filterDescription.field == 'bkInvoiceAccountKey' && (filterDescription.value == customerAccountKey)) {
            filterApplied = true;
          }
        });
      }
    });

    if (!filterApplied) {
      gridState.filter.filters.push(ordersForAccountFilter);
    }
  }

  getOrderDetail(orderId: string, accountKey: string): Observable<OrderHeader> {
    const url = this.baseUrl + "orderheaders"
      + `?$select=${this.headerSelector}`
      + `&$filter=(Id eq '${orderId}' and (bkInvoiceAccountKey eq '${accountKey}' or bkCustomerDeliveryKey eq '${accountKey}'))`
      + `&$expand=OrderLines($select=${this.orderLineSelector})`
      + "&$top=1";

    return this.httpClient.get<ODataResponse<OrderHeader[]>>(url).pipe(map(response => {
      if (response && response.value && response.value[0]) {
        this.calculateFieldsFromLines(response.value[0]);
        response.value.forEach(x => this.setSalesStatusName(x));
        return response.value[0];
      }
      return undefined;
    }));
  }

  getOrderAgentReference(orderId: string, accountKey: string): Observable<string> {
    const url = this.baseUrl + "orderheaders"
      + `?$select=AgentReference`
      + `&$filter=(Id eq '${orderId}' and (bkInvoiceAccountKey eq '${accountKey}' or bkCustomerDeliveryKey eq '${accountKey}'))`
      + "&$top=1";

    return this.httpClient.get<ODataResponse<OrderHeader[]>>(url).pipe(map(response => {
      if (response && response.value && response.value[0] && response.value[0].AgentReference) {
        return response.value[0].AgentReference;
      }
      return undefined;
    }));
  }

  calculateFieldsFromLines(header: OrderHeader) {
    this.companyAdminConfigurationStore.get().subscribe(companyAdminConfiguration => {
      if (header.OrderLines?.length == 0) {
        return;
      }
      header.OrderLines.sort((x, y) => x.LineNum > y.LineNum ? 1 : -1);
      header.TotalLines = header.OrderLines?.length;
      let lineAmountSum = 0;
      header.OrderLines.forEach(o => {
        if (o.LineAmount) {
          lineAmountSum += o.LineAmount;
        }
        if (o.SalesStatus == salesStatusStrings.Delivered && o.SalesStatusCode == 2) {
          o.SalesStatus = salesStatusStrings.Shipped;
        }
      });
      header.TotalAmount = lineAmountSum;
      this.calculateVatFields(header, companyAdminConfiguration);
      this.mapSalesStatus(header);
      this.setProductKeyWithoutCompanyKey(header);
      this.removeCompanyKeyFromSalesOrderHeader(header);
      this.changeNegativeSignOnChosenLineFields(header);
    });
  }

  private setSalesStatusName(order: OrderHeader): void {
    if (order?.SalesStatus === "Open order" || order?.SalesStatus === "PlacedInAX") {
      order.SalesStatus = this.translateService.instant("order.salesStatus.beingProcessed");
    }
    else if (order?.SalesStatus === "Delivered") {
      order.SalesStatus = this.translateService.instant("order.salesStatus.delivered");
    }
    else if (order?.SalesStatus === "Invoiced") {
     order.SalesStatus = this.translateService.instant("order.salesStatus.invoiced");
    }
  }

  private calculateVatFields(header: OrderHeader, companyAdminConfiguration: CompanyAdminConfiguration) {
    if (!companyAdminConfiguration || !companyAdminConfiguration.vatRate || !header.TotalAmount) {
      return;
    }

    const vatValue = companyAdminConfiguration.vatRate / 100.0;

    header.TotalVat = header.TotalAmount * vatValue;
    header.TotalAmountIncludingVat = header.TotalAmount + header.TotalVat;
  }

  mapSalesStatus(header: OrderHeader) {
    if (!header.SalesStatus || header.SalesStatus != salesStatusStrings.Delivered) {
      return;
    }
    header.SalesStatus = salesStatusStrings.Shipped;
  }

  private setProductKeyWithoutCompanyKey(header: OrderHeader) {
    const keySeparator = "_";
    if (!header.OrderLines) {
      return;
    }
    header.OrderLines.forEach(line => {
      if (!line.bkProductKey || !line.bkProductKey.includes(keySeparator)) {
        return;
      }

      line.bkProductKeyNoCode = line.bkProductKey.substring(0, line.bkProductKey.indexOf(keySeparator));
    });
  }

  private removeCompanyKeyFromSalesOrderHeader(header: OrderHeader) {
    if (!header.bkCompanyKey || !header.bkSalesOrderHeader) {
      return;
    }
    header.bkSalesOrderHeader = header.bkSalesOrderHeader.substring(0, header.bkSalesOrderHeader.lastIndexOf(header.bkCompanyKey) - 1);
  }

  private changeNegativeSignOnChosenLineFields(header: OrderHeader) {
    if (!header || !header.OrderLines) {
      return;
    }
    header.OrderLines.forEach(line => {

      if (line.PhysicalReserved < 0) {
        line.PhysicalReserved *= -1;
      }
      if (line.OnOrder < 0) {
        line.OnOrder *= -1;
      }
      if (line.Picked < 0) {
        line.Picked *= -1;
      }
      if (line.Invoiced < 0) {
        line.Invoiced *= -1;
      }
      if (line.Shipped < 0) {
        line.Shipped *= -1;
      }
    });
  }
}
