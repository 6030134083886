import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoaderSize } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'ntw-loading-dropdownlist',
  templateUrl: './loading-dropdownlist.component.html',
  styleUrls: ['./loading-dropdownlist.component.scss']
})
export class LoadingDropdownlistComponent {
  @Input() public defaultItem = undefined;
  @Input() public data = undefined;
  @Input() public textField = "";
  @Input() public valueField = "";
  @Input() public loaderSize: LoaderSize = "medium";
  @Input() public loading = false;
  @Input() public disabled = false;
  @Input() public showNgContentOnDisabled = false; // If true, the ng-content will be shown when the dropdown is disabled

  @Output() public valueChange = new EventEmitter();
}
