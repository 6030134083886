import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ntw-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  @Input() public close: () => void;
  @Input() public title: string = this.translateService.instant('confirmDialog.defaultTitle');
  @Input() public message: string = this.translateService.instant('confirmDialog.defaultMessage');
  @Output() public decision: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private translateService: TranslateService) { }
}
