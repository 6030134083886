import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Company } from "../../models/company";
import { CosmosCustomer } from "../../models/customer";

@Injectable({
    providedIn: 'root'
})

export abstract class ICustomerService {
    public abstract getCustomerByCode(axCode: string): Observable<CosmosCustomer>;
    public abstract getCompanyByCustomerRegisterCode(companyRegisterCode: string): Observable<Company>;
    public abstract isAgentSalesCustomer(axCode: string): Observable<boolean>;
}
