import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ComponentCanDeactivate } from './component-can-deactivate';

export const canDeactivateGuard: CanDeactivateFn<ComponentCanDeactivate> = (
    component: ComponentCanDeactivate,
    _route,
    _state,
    _nextState,
    translateService: TranslateService = inject(TranslateService)
) => {
    const message = translateService.instant("alerts.unsavedChanges");
    if (!component.canDeactivate()) {
        // eslint-disable-next-line no-alert
        return confirm(message);
    }
    return true;
}
