import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerSettings } from '../models/customer-settings';
import { ICustomerSettingsService } from '../services/customer-settings/customer-settings.service.interface';
import { Store } from './store.base';

@Injectable({
  providedIn: 'root'
})
export class CustomerSettingsStore extends Store<CustomerSettings> {
  private bkCustomerKey = '';

  constructor(private customerSettingsService: ICustomerSettingsService) {
    super();
  }

  public override isLoaded(): boolean {
    const userSettings = this.cachedItem.getValue();
    return userSettings !== undefined && userSettings.id !== undefined;
  }

  public load(bkCustomerKey: string): BehaviorSubject<CustomerSettings | null | undefined> {
  if (this.bkCustomerKey === bkCustomerKey) {
    return this.get();
  }

  this.loading.next(true);

  this.customerSettingsService.get(bkCustomerKey).subscribe(settings => {
    this.bkCustomerKey = bkCustomerKey;
    this.cachedItem.next(settings !== undefined ? settings : null);
    this.loading.next(false);
  });

    return this.get();
  }

  public reload(): BehaviorSubject<CustomerSettings | null | undefined> {
    const bkCustomerKey = this.bkCustomerKey;
    this.bkCustomerKey = '';
    return this.load(bkCustomerKey);
  }
}
