import { ColumnSettings } from "src/app/shared/models/ui/column-settings";

export const OrderLineColumns: ColumnSettings[] = [
    {
        field: "axCode", width: 100, minResizableWidth: 60,
        title: "portalOrderLine.AXCode"
    },
    {
        field: "name", width: 120, minResizableWidth: 120,
        title: "portalOrderLine.name"
    },
    {
        field: "chosenColorShade.Code", width: 90, minResizableWidth: 90,
        title: "portalOrderLine.color"
    },
    {
        field: "brandName", width: 90, minResizableWidth: 90,
        title: "portalOrderLine.brandName"
    },
    {
        field: "quantity", width: 90, minResizableWidth: 90,
        title: "portalOrderLine.quantity",
        filterable: false, sortable: false,
        editor: "numeric",
        editorSettings: {
            editAll: true,
            displayValue: true,
            autoCorrect: true,
            decimals: 0,
            format: 'n0',
            min: 1,
            max: 999999
        }
    },
    {
        field: "unitNetPrice", width: 90, minResizableWidth: 90, filter: 'numeric', format: "{0:n2}",
        title: "portalOrderLine.unitNetPrice"
    },
    {
        field: "lineAmount", width: 90, minResizableWidth: 90, filter: 'numeric', format: "{0:n2}",
        title: "portalOrderLine.lineAmount"
    },
    {
        field: "masterBasicUMCode", width: 90, minResizableWidth: 90,
        title: "portalOrderLine.masterBasicUMCode"
    },
    {
        field: "netWeightKG", width: 90, minResizableWidth: 90, filter: 'numeric', format: "{0:n2}",
        title: "portalOrderLine.netWeightKG"
    },
    {
        field: "grossWeightKG", width: 90, minResizableWidth: 90, filter: 'numeric', format: "{0:n2}",
        title: "portalOrderLine.grossWeightKG"
    },
    {
        field: "volumeL", width: 90, minResizableWidth: 90, filter: 'numeric', format: "{0:n2}",
        title: "portalOrderLine.volumeL"
    },
    {
        field: "palletSizeName", width: 90, minResizableWidth: 90,
        title: "portalOrderLine.palletSizeName"
    },
];
