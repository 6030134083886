import { CosmosCustomer } from "./models/customer";

export class Utils {
    //cache number formats
    private static numberFormats: { [key: string]: Intl.NumberFormat } = {};

    public static isNullOrWhitespace(value: string): boolean {
        if (value) {
            return !value.trim().length;
        }
        return true;
    }

    public static TryAddCurrencyCode(value: string, customer: CosmosCustomer): string {
        if (Utils.isNullOrWhitespace(value)) {
            return value;
        }
        if (customer && customer.CurrencyCode) {
            return `${value} (${customer.CurrencyCode.toUpperCase()})`;
        }
        return value;
    }

    public static FormatNumber(value: number, fractionDigits?: number, locales: string | string[] = 'sv-SE'): string {
        fractionDigits ??= 0;
        const key = `${locales}-${fractionDigits}`;
        if (!Utils.numberFormats[key]) {
            Utils.numberFormats[key] = new Intl.NumberFormat(locales, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits });
        }
        return Utils.numberFormats[key].format(value ?? 0);
    }

    public static FormatDate(value: Date, locales: string | string[] = 'en-GB'): string {
        if (value) {
            return value.toLocaleDateString(locales);
        }
        return '';
    }

    /**
      * Splits an array into chunks of a specified size.
      * @param array The array to split.
      * @param chunkSize The size of each chunk.
      * @returns An array of arrays, where each inner array contains up to chunkSize elements from the original array.
      */
    public static chunkArray<T>(array: T[], chunkSize: number): T[][] {
        const results = [];
        // While the original array still has elements
        while (array.length) {
            // Remove the first chunkSize elements from the original array and add them to the results array
            results.push(array.splice(0, chunkSize));
        }
        return results;
    }
}
