import { ColumnSettings } from "src/app/shared/models/ui/column-settings";

export const orderLineColumns: ColumnSettings[] = [
    {
        field: "LineNum", width: 87, minResizableWidth: 87,
        title: "orderLine.LineNumber"
    },
    {
        field: "bkProductKeyNoCode", width: 130, minResizableWidth: 130,
        title: "orderLine.bkProductKey"
    },
    {
        field: "BasePaintItemName", width: 160, minResizableWidth: 160,
        title: "orderLine.BasePaintItemName"
    },
    {
        field: "bkRequestedShipmentDateKey", minResizableWidth: 100, width: 100, filter: "date", format: "dd/MM/yyyy",
        title: "orderLine.bkRequestedShipmentDateKey"
    },
    {
        field: "UnitNetPriceAfterDiscount", width: 80, filter: 'numeric', minResizableWidth: 80,
        title: "orderLine.UnitNetPriceAfterDiscount", format: "{0:n2}",
    },
    {
        field: "OrderedQty", width: 95, filter: 'numeric', minResizableWidth: 95,
        title: "orderLine.OrderQuantity", format: "{0:n0}",
    },
    {
        field: "SalesUnit", width: 90, minResizableWidth: 90,
        title: "orderLine.UnitofMeasure"
    },
    {
        field: "LineAmount", width: 85, filter: 'numeric', minResizableWidth: 85,
        title: "orderLine.LineAmount", format: "{0:n0}",
    },
    {
        field: "SalesStatus", width: 85, filter: 'numeric', minResizableWidth: 85,
        title: "orderLine.SalesStatus"
    },
    {
        field: "DeliveryMode", width: 105, filter: 'numeric', minResizableWidth: 105,
        title: "orderLine.DeliveryMode"
    },
    {
        field: "PhysicalReserved", width: 95, filter: 'numeric', minResizableWidth: 95,
        title: "orderLine.PhysicalReserved", format: "{0:n0}",
    },
    {
        field: "OnOrder", width: 90, filter: 'numeric', minResizableWidth: 90,
        title: "orderLine.OnOrder"
    },
    {
        field: "Picked", width: 90, filter: 'numeric', minResizableWidth: 90,
        title: "orderLine.Picked"
    },
    {
        field: "Shipped", width: 90, filter: 'numeric', minResizableWidth: 90,
        title: "orderLine.Shipped"
    },
    {
        field: "Invoiced", width: 95, filter: 'numeric', minResizableWidth: 95,
        title: "orderLine.Invoiced"
    },
    {
        field: "DeliveryReminderQty", width: 105, filter: 'numeric', minResizableWidth: 105,
        title: "orderLine.DeliveryReminderQty", format: "{0:n0}",
    },
];
