import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PortalOrderProduct } from 'src/app/shared/models/order-creation/portal-order-product';
import { InventoryOffline, InventoryOnline } from 'src/app/shared/models/products/inventory';
import { Product } from 'src/app/shared/models/products/product';

@Injectable()
export abstract class IInventoryService {
    baseUrl: string;

    abstract getInventoryForWarehouseByProductsOffline(bkWarehouseKey: string, products: Product[]): Observable<InventoryOffline[]>;
    abstract getInventoryForWarehouseByProductsOnline(bkCompanyKey: string, bkWarehouseKey: string, products: PortalOrderProduct[]): Observable<InventoryOnline[]>;
}
