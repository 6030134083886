import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CrmContact } from '../models/contact';
import { IContactService } from '../services/contact/contact.service.interface';
import { Store } from './store.base';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserContactStore extends Store<CrmContact> {
  constructor(private contactService: IContactService) {
    super();
  }

  public override isLoaded(): boolean {
    const contact = this.cachedItem.getValue();
    return contact !== undefined && contact.contactid !== undefined;
  }

  public override get(): BehaviorSubject<CrmContact | null | undefined> {
    const contact = this.cachedItem.getValue();
    if (contact !== undefined && contact.contactid !== undefined) {
      return this.cachedItem;
    }

    this.loading.next(true);

    this.contactService.getCurrentUsersContact().subscribe(contact => {
      if (contact !== undefined && contact.contactid !== undefined) {
        this.cachedItem.next(contact);
      }
      this.loading.next(false);
    });

    return this.cachedItem;
  }
}
