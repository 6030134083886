<kendo-dialog
    [title]="this.title"
    (close)="close()"
    [minWidth]="250"
    [width]="450"
>
<p style="margin: 30px; text-align: center;">
  {{this.message}}
</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="this.decision.emit(false)">
      {{'shared.false' | translate}}
    </button>
    <button kendoButton (click)="this.decision.emit(true)" themeColor="primary">
      {{'shared.true' | translate}}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>