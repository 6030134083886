export const openOrdersDetailsFormName = "openOrdersDetailsForm";
export const openOrdersDetailsLineColumnsFormName = "openOrdersDetailsLineColumns";
export const openOrdersListLineColumnsFormName = "openOrdersListLineColumns";

export const selectProductsLineColumnsFormName = 'selectProductsLineColumns';
export const orderSummaryFormName = "orderSummaryForm";
export const orderSummaryLineColumnsFormName = "orderSummaryLineColumns";

export const placedOrdersDetailsFormName = "placedOrderDetailsForm";
export const placedOrdersDetailsLineColumnsFormName = "placedOrderDetailsLineColumns";
export const placedOrdersListLineColumnsFormName = "placedOrdersListLineColumns"

export const invoicesDetailsFormName = 'invoiceDetailsForm';
export const invoicesDetailsLineColumnsFormName = 'invoiceDetailsLineColumns';
export const invoicesListLineColumnsFormName = 'invoicesListLineColumns';
