import { CosmosCustomer } from "../customer";
import { PortalOrderProduct } from "../order-creation/portal-order-product";
import { ProductWithInventoryAndQuantity } from "../order-creation/product-with-quantity";

export class PricelistMultiRequest {
    companyId: string;
    currency: string;
    custvendAc: string;
    priceDiscModule: 0 | 1;
    request: RequestItem;


    constructor(products: PortalOrderProduct[] | ProductWithInventoryAndQuantity[], customer: CosmosCustomer) {
        this.companyId = customer.TikkurilaCompanyRegisterCode;
        this.currency = customer.CurrencyCode?.toLowerCase();
        this.custvendAc = customer.AXCode;
        this.priceDiscModule = 0;
        this.request = new RequestItem(products, customer);
    }
}

export class RequestItem {
    params: RequestItemParams;

    constructor(products: PortalOrderProduct[] | ProductWithInventoryAndQuantity[], customer: CosmosCustomer) {
        this.params = new RequestItemParams(products, customer);
    }
}

class RequestItemParams {
    accountType: "Customer";
    currencyId: string;
    product: RequestParamsProduct[];

    constructor(products: PortalOrderProduct[] | ProductWithInventoryAndQuantity[], customer: CosmosCustomer) {
        if (!products || !customer) {
            return;
        }
        this.accountType
        this.currencyId = customer.CurrencyCode?.toLowerCase();
        this.product = [];
        products.forEach((portalProduct: PortalOrderProduct | ProductWithInventoryAndQuantity) => {
            const paramProduct = new RequestParamsProduct(portalProduct);
            this.product.push(paramProduct);
        });
    }
}

class RequestParamsProduct {
    id: string;
    unitofMeasure: string;
    qty: number;

    constructor(product: PortalOrderProduct | ProductWithInventoryAndQuantity) {
        if (!product) {
            return;
        }
        if ((product as PortalOrderProduct).axCode) {
            const portalProduct = product as PortalOrderProduct;
            this.id = portalProduct.axCode;
            this.unitofMeasure = portalProduct.masterBasicUMCode;
            this.qty = portalProduct.quantity;
        } else if ((product as ProductWithInventoryAndQuantity).AXCode) {
            const productWIQ = product as ProductWithInventoryAndQuantity;
            this.id = productWIQ.AXCode;
            this.unitofMeasure = productWIQ.MasterBasicUMCode;
            this.qty = productWIQ.Quantity;
        }
    }
}
