/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { environment } from 'src/environments/environment';
import { Configuration, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1A_ACCOUNTLINK_SI'
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://tikkurilab2cuat.b2clogin.com/tikkurilab2cuat.onmicrosoft.com/B2C_1A_ACCOUNTLINK_SI'
        }
    },
    authorityDomain: "tikkurilab2cuat.b2clogin.com"
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: '94a8338b-d375-4583-bfb8-31869d03217a',
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: environment.msalRedirect,
        postLogoutRedirectUri: environment.msalRedirect,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
                if (containsPii) {
                    return;
                }
                if (level == LogLevel.Error) {
                    console.error(message);
                }
            },
            piiLoggingEnabled: false
        }
    }
}

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const b2cScopes: string[] = [
    'https://tikkurilab2cuat.onmicrosoft.com/api/addresses.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/colorshades.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/companyadminconfiguration.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/companydata.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/customers.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/customersettings.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/customersettings.write',
    'https://tikkurilab2cuat.onmicrosoft.com/api/customertasks.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/customertasks.write',
    'https://tikkurilab2cuat.onmicrosoft.com/api/inventories.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/invoices.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/orders.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/portalorders.delete',
    'https://tikkurilab2cuat.onmicrosoft.com/api/portalorders.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/portalorders.submit',
    'https://tikkurilab2cuat.onmicrosoft.com/api/portalorders.write',
    'https://tikkurilab2cuat.onmicrosoft.com/api/pricelists.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/products.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/usersettings.read',
    'https://tikkurilab2cuat.onmicrosoft.com/api/usersettings.write',
];

export const protectedResources = {
    api: {
        endpoint: environment.apiBaseUrl,
        scopes: b2cScopes,
    },
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: []
};
