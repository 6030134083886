import { trigger, state, style, transition, animate } from '@angular/animations';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { cartIcon, chevronDownIcon, xIcon } from '@progress/kendo-svg-icons';
import { combineLatest } from 'rxjs';
import { CrmAccount } from 'src/app/shared/models/account';
import { SecurityService } from 'src/app/shared/security/security.service';
import { CompanyNotificationService } from 'src/app/shared/services/company-notification/company-notification.service';
import { ICustomerService } from 'src/app/shared/services/customer/customer.service.interface';
import { KendoAlertService } from 'src/app/shared/services/kendo-alerts.service';
import { MaintenanceService } from 'src/app/shared/services/maintenance/maintenance.service';
import { CompanyAdminConfigurationStore } from 'src/app/shared/stores/company-admin-configuration.store';

@Component({
  selector: 'ntw-navigation-top-bar',
  templateUrl: './navigation-top-bar.component.html',
  styleUrls: ['./navigation-top-bar.component.scss', '../../shared/styles/button.scss'],
  animations: [
    trigger('collapse', [
      state('open', style({
        maxHeight: '160px',
        overflowY: 'auto',
      })),
      state('closed', style({
        maxHeight: '40px',
        overflow: 'clip',
      })),
      transition('open => closed', [
        style({ overflow: 'clip' }),
        animate('0.2s ease', style({ maxHeight: '40px' })),
      ]),
      transition('closed => open', [
        animate('0.2s ease', style({ maxHeight: '160px' })),
      ]),
    ]),
    trigger('rotate', [
      state('collapsed', style({ transform: 'rotate(180deg)' })),
      state('expanded', style({ transform: 'rotate(0deg)' })),
      transition('collapsed => expanded', [
        style({ backgroundColor: 'transparent' }),
        animate('0.2s ease', style({ transform: 'rotate(0deg)' }))
      ]),
      transition('expanded => collapsed', [
        style({ backgroundColor: 'transparent' }),
        animate('0.2s ease', style({ transform: 'rotate(180deg)' }))
      ]),
    ]),
  ]
})
export class NavigationTopBarComponent implements OnInit, AfterViewChecked {
  @ViewChild('notificationContent') notificationContent?: ElementRef<HTMLDivElement>;

  selectedAccount: CrmAccount;
  isAgentSalesCustomer = false;
  loading = true;

  public icons = {
    cart: cartIcon,
    chevronDown: chevronDownIcon,
    close: xIcon
  }

  isMaintenanceActive = false;

  isNotificationOverflown = false;
  isCollapsed = true;

  isNotificationActive = false;
  notificationLevel = '';
  notificationMessage = '';

  constructor(public securityService: SecurityService,
    private router: Router,
    private kendoAlertService: KendoAlertService,
    private companyAdminConfigurationStore: CompanyAdminConfigurationStore,
    private companyNotificationService: CompanyNotificationService,
    private changeDetectorRef: ChangeDetectorRef,
    private customerService: ICustomerService,
    private maintenanceService: MaintenanceService,
  ) {
    this.companyNotificationService.isNotificationActive.subscribe({
      next: isNotificationActive => {
        this.isNotificationActive = isNotificationActive;
      }
    });
    this.companyNotificationService.notificationConfiguration.subscribe({
      next: notificationConfiguration => {
        if (notificationConfiguration) {
          this.notificationLevel = notificationConfiguration.notificationLevel;
          this.notificationMessage = notificationConfiguration.message;
        }
      }
    });
    this.maintenanceService.isMaintenanceActive.subscribe({
      next: isMaintenanceActive => {
        this.isMaintenanceActive = isMaintenanceActive;
      }
    });
  }

  ngOnInit(): void {
    const $isAdminConfigLoading = this.companyAdminConfigurationStore.isLoading();
    const $getCurrentAccount = this.securityService.getCurrentAccount();

    combineLatest([$isAdminConfigLoading, $getCurrentAccount]).subscribe({
      next: ([isAdminConfigLoading, currentAccount]) => {
        const isAdminConfigLoaded = this.companyAdminConfigurationStore.isLoaded();
        this.loading = isAdminConfigLoading || !isAdminConfigLoaded || !currentAccount;
      }
    });

    $getCurrentAccount.subscribe({
      next: account => {
        this.selectedAccount = account;
        if (!this.selectedAccount) {
          return;
        }
        this.customerService.isAgentSalesCustomer(this.selectedAccount.ntw_axcode).subscribe(x => this.isAgentSalesCustomer = x);
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
      }
    });
  }

  ngAfterViewChecked(): void {
    if (!this.notificationContent) {
      return;
    }

    const element = this.notificationContent.nativeElement;
    const isOverflow = element.scrollHeight > 40;
    if (isOverflow !== this.isNotificationOverflown) {
      this.isNotificationOverflown = isOverflow;
      this.changeDetectorRef.detectChanges();
    }
  }

  onLogoClick(): string {
    if (!this.selectedAccount) {
      return '';
    }

    return `account/${this.selectedAccount.accountid}`;
  }

  newOrderClick(): void {
    if (!this.selectedAccount) {
      return;
    }
    this.router.navigate([`account/${this.selectedAccount.accountid}/draftorder`]);
  }
}
