import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, debounceTime } from 'rxjs';
import { IAccountService } from '../account/services/account.service.interface';
import { SecurityService } from '../shared/security/security.service';
import { KendoAlertService } from '../shared/services/kendo-alerts.service';
import { CompanyAdminConfigurationStore } from '../shared/stores/company-admin-configuration.store';
import { UserSettingsStore } from '../shared/stores/user-settings.store';

@Component({
  selector: 'ntw-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss']
})
export class SelectAccountComponent implements OnInit {
  loading = true;
  areAccountsLoading = true;

  constructor(private accountService: IAccountService,
    private router: Router,
    public securityService: SecurityService,
    private kendoAlertService: KendoAlertService,
    private companyAdminConfigurationStore: CompanyAdminConfigurationStore,
    private userSettingsStore: UserSettingsStore
  ) { }

  ngOnInit(): void {
    const $isAdminConfigLoading = this.companyAdminConfigurationStore.isLoading();
    const $isUserSettingsLoading = this.userSettingsStore.isLoading();

    combineLatest([$isAdminConfigLoading, $isUserSettingsLoading]).pipe(debounceTime(500)).subscribe({
      next: ([isAdminConfigLoading, isUserSettingsLoading]) => {
        const isAdminConfigLoaded = this.companyAdminConfigurationStore.isLoaded();
        const isUserSettingsLoaded = this.userSettingsStore.isLoaded();
        this.loading = isAdminConfigLoading || !isAdminConfigLoaded || isUserSettingsLoading || !isUserSettingsLoaded;
      }
    });

    this.accountService.getAccounts().subscribe({
      next: accounts => {
        this.areAccountsLoading = false;
        if (accounts.length > 0) {
          const invoiceAccounts = accounts.filter(a => !a._parentaccountid_value);
          const account = invoiceAccounts.length === 1 ? invoiceAccounts[0] : null ?? accounts.length === 1 ? accounts[0] : null;
          if (account) {
            this.securityService.setCurrentAccount(account);
            this.router.navigate(['account', account.accountid]);
          }
        }
      },
      error: error => {
        this.kendoAlertService.showErrorAlert(error);
      }
    });

  }

}
