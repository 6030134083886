/* eslint-disable @angular-eslint/no-output-on-prefix */
import { EventEmitter, Injectable, Output } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  // The idle event should emit 30 seconds (30 seconds) after the user has stopped interacting with the application.
  // The second idle event should emit 60 seconds (30 seconds * 2) after the user has stopped interacting with the application.
  public static readonly idleAfter = 30;
  // The timeout warning event should emit 30 minutes (60 * 30 seconds) after the user has stopped interacting with the application.
  public static readonly timeoutWarningAfter = Math.max(60 * 30 - IdleService.idleAfter, 0);
  // The timeout event should emit 30 minutes (60 * 30 seconds) + IdleService.timeoutWarningAfter after the user has stopped interacting with the application.
  public static readonly timeoutAfter = 60 * 30 + IdleService.timeoutWarningAfter;

  @Output() onIdleStart = new EventEmitter();
  @Output() onIdleEnd = new EventEmitter();
  @Output() onTimeoutWarning = new EventEmitter<number>();
  @Output() onTimeout = new EventEmitter();

  /// <summary>
  /// Emitted after double the idle start time has elapsed.
  /// </summary>
  @Output() onIdleStartDouble = new EventEmitter();

  private onTimeoutFunctions: (() => Observable<unknown>)[] = [];

  constructor(
    private idle: Idle,
  ) {
    this.setupTimeout();
  }

  private setupTimeout() {
    this.idle.setIdle(IdleService.idleAfter);
    this.idle.setTimeout(IdleService.timeoutAfter);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      this.onIdleStart.emit();
    });

    this.idle.onIdleEnd.subscribe(() => {
      this.onIdleEnd.emit();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      if (countdown === (IdleService.timeoutAfter - IdleService.idleAfter))
      {
        this.onIdleStartDouble.emit();
      }
      if (countdown < (IdleService.timeoutAfter - IdleService.timeoutWarningAfter))
      {
        this.onTimeoutWarning.emit(countdown);
      }
    });

    this.idle.onTimeout.subscribe(() => {
      if (this.onTimeoutFunctions.length == 0) {
        this.onTimeout.emit();
        return;
      }

      forkJoin(this.onTimeoutFunctions.map(o => o())).subscribe(() => {
        this.onTimeout.emit();
      });
    });
  }

  /// <summary>
  /// Starts the idle service.
  /// </summary>
  public watch() {
    this.idle.watch();
  }

  /// <summary>
  /// Registers a function to be called when the idle service times out.
  /// </summary>
  public registerOnTimeoutFunction(onTimeoutFunction: () => Observable<unknown>) {
    this.onTimeoutFunctions.push(onTimeoutFunction);
  }

  /// <summary>
  /// Unregisters a function to be called when the idle service times out.
  /// </summary>
  public unregisterOnTimeoutFunction(onTimeoutFunction: () => Observable<unknown>) {
    this.onTimeoutFunctions = this.onTimeoutFunctions.filter(o => o != onTimeoutFunction);
  }
}
