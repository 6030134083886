import { Component, Input } from '@angular/core';
import { DashboardCard } from 'src/app/shared/models/dashboard-card';

@Component({
  selector: 'ntw-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent {
  @Input() card?: DashboardCard;

  public GetImageCountArray(): number[] {
    return Array.from(Array(this.card?.imageCount).keys());
  }
}
