import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StepperActivateEvent } from '@progress/kendo-angular-layout';
import { cartIcon, redoIcon, saveIcon, undoIcon } from '@progress/kendo-svg-icons';
import { Observable, Subject, of } from 'rxjs';
import { catchError, concatMap, retry, skipWhile, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IPortalOrderService } from '../portal-order/services/portal-orders.service.interface';
import { IInventoryService } from '../products/services/inventory.service.interface';
import { OrderProcessSteps, Step, StepName } from '../shared/constants/order-process/order-process-steps';
import { StringConstants } from '../shared/constants/string-constants';
import { CrmAccount } from '../shared/models/account';
import { ColorShadeValue } from '../shared/models/colorshade/colorshade-value';
import { CosmosCustomer } from '../shared/models/customer';
import { PortalOrder, PortalOrderStatus } from '../shared/models/order-creation/portal-order';
import { PortalOrderProduct } from '../shared/models/order-creation/portal-order-product';
import { InventoryOnline, InventoryOnlineStatus } from '../shared/models/products/inventory';
import { Warehouse } from '../shared/models/warehouse';
import { ComponentCanDeactivate } from '../shared/security/guards/component-can-deactivate';
import { SecurityService } from '../shared/security/security.service';
import { ICustomerService } from '../shared/services/customer/customer.service.interface';
import { IdleService } from '../shared/services/idle/idle.service';
import { KendoAlertService } from '../shared/services/kendo-alerts.service';
import { OrderCartService } from '../shared/services/order-cart.service';
import { StorageService } from '../shared/services/storage/storage-service';
import { CustomerSettingsStore } from '../shared/stores/customer-settings.store';
import { IOrderProcessService } from './services/order.process.service.interface';

@Component({
  templateUrl: './order-process.component.html',
  styleUrls: ['./order-process.component.scss', './../shared/styles/button.scss']
})
export class OrderProcessComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  private currentCustomer: CosmosCustomer;
  private currentAccount: CrmAccount;
  public isDetailsStepValid = false;
  public portalOrder: PortalOrder;
  public inventoryOnline: InventoryOnline[] = [];
  public stepsLoading = true;
  public loading = true;
  public orderLocallyUpdated = false;
  public orderUpdateInProgress = false;
  public pricesUpdatedOn: Date | undefined;
  private pricesUpdating = false;
  public confirmClearCartDialogVisible = false;
  private isSingleWarehouse = false;
  private warehouse?: Warehouse;
  private availableWarehouses: Warehouse[] = [];
  public get canSelectWarehouse(): boolean {
    return !this.portalOrder?.orderProducts || this.portalOrder?.orderProducts.length === 0;
  }
  public termsAndConditionsPath: string;

  public get isCompanyNorway(): boolean {
    return this.portalOrder?.bkCompanyNumber === "210";
  }

  public steps: Step[];

  private _currentStep?: Step;
  public get currentStep(): Step {
    return this._currentStep;
  }
  public set currentStep(value: Step) {
    this._currentStep = value;
    this._currentStepIdx = this.steps.findIndex(s => s.stepName === value.stepName);
  }

  private _currentStepIdx?: number;
  public get currentStepIdx(): number {
    return this._currentStepIdx;
  }
  public set currentStepIdx(value: number) {
    this._currentStepIdx = value;
    this._currentStep = this.steps[value];
  }

  public get nextStep(): Step {
    return this.steps[this.currentStepIdx + 1];
  }

  private getStepByName(stepName: StepName): Step | undefined {
    return this.steps.find(s => s.stepName === stepName);
  }

  public isFirstStep(): boolean {
    return this.currentStepIdx === 0;
  }

  public isLastStep(): boolean {
    return this.currentStepIdx === this.steps.length - 1;
  }

  public icons = {
    cart: cartIcon,
    redo: redoIcon,
    save: saveIcon,
    undo: undoIcon,
  }

  public productSearchValue = '';

  private params: ParamMap;

  constructor(
    private activeRoute: ActivatedRoute,
    private customerService: ICustomerService,
    private customerSettingsStore: CustomerSettingsStore,
    private idleService: IdleService,
    private inventoryService: IInventoryService,
    private kendoAlertService: KendoAlertService,
    private notificationService: KendoAlertService,
    private orderCartService: OrderCartService,
    private orderProcessService: IOrderProcessService,
    private portalOrderService: IPortalOrderService,
    private router: Router,
    private securityService: SecurityService,
    private storageService: StorageService<ColorShadeValue>,
    private translateService: TranslateService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.activeRoute.paramMap.pipe(concatMap(params => {
      this.params = params;
      if (history?.state?.stepIdx) {
        this.loading = true;
      }

      return this.orderProcessService.getWarehouses();
    }), concatMap(warehouses => {
      this.availableWarehouses = warehouses;
      this.isSingleWarehouse = warehouses?.length === 1;
      if (this.isSingleWarehouse) {
        this.warehouse = warehouses[0];
      }

      this.loadSteps();
      return this.securityService.getCurrentAccount().pipe(skipWhile(account => !account));
    }), concatMap(account => {
      this.currentAccount = account;
      return this.customerService.getCustomerByCode(account.ntw_axcode);
    }), tap(customer => {
      this.currentCustomer = customer;
      this.setIsOrderUpdated(false);
      this.initializeOrder();
    }), catchError(error => {
      this.kendoAlertService.showErrorAlert(error);
      return of(null);
    })).subscribe();

    this.setupLanguageChangeHandler();
    this.setupSaveOnIdle();
  }

  ngOnDestroy(): void {
    this.unRegisterSaveOnAutomaticLogout();
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  private setupLanguageChangeHandler(): void {
    this.translateService.onLangChange.pipe(takeUntil(this._destroying$)).subscribe(() => {
      const currentStepName = this.currentStep.stepName;
      this.loadSteps();
      this.currentStep = this.getStepByName(currentStepName);
    });
  }

  private loadSteps(): void {
    this.stepsLoading = true;

    this.steps = JSON.parse(JSON.stringify(OrderProcessSteps));
    if (this.isSingleWarehouse) {
      this.steps = this.steps.filter(x => x.stepName !== 'warehouse');
    }

    this.steps.forEach((s, i) => s.text = (i + 1).toString().padStart(2, '0'));
    this.steps.forEach(s => s.label = this.translateService.instant(s.label));
    this.currentStep = this.steps[0];

    this.stepsLoading = false;
  }

  canDeactivate(): boolean {
    return !this.orderLocallyUpdated;
  }

  onStepChange($event: StepperActivateEvent): void {
    const newStep = $event.step as Step;

    $event.preventDefault();
    if (!this.canProceedToStep(newStep.stepName)) {
      return;
    }

    this.trySaveOrder(() => this.currentStep = newStep);
    this.storageService.RemoveFromStorage(StringConstants.LatestColorShadeStorage);
  }

  initializeOrder() {
    this.initializeOrderFromState();
    if (this.portalOrder && this.portalOrder.id) {
      this.updateOrderByCustomer().subscribe(() => {
        this.loading = false;
        this.registerSaveOnAutomaticLogout();
      });
      return;
    }
    const orderId = this.params?.get("id");
    this.orderCartService.getOrCreatePortalOrder(orderId).subscribe({
      next: order => {
        if (order) {
          this.portalOrder = order;
          this.initializeInventoryOnline();
        }
        else {
          this.portalOrder = this.orderCartService.createNewOrder();
        }

        if (this.portalOrder.orderSubmissionStatus === PortalOrderStatus.Draft) {
          // If there is only one warehouse and it is different from the warehouse in the order,
          // set the warehouse to the available warehouse
          if (this.isSingleWarehouse && this.portalOrder.warehouse && this.warehouse
           && this.portalOrder.warehouse.bkWarehouseKey !== this.warehouse.bkWarehouseKey) {
            this.portalOrder.warehouse = this.warehouse;
            if (this.portalOrder.warehouse) {
              this.orderCartService.clearProductsInCart();
            }
          }
          // If there are multiple warehouses and the order has a warehouse that is not available, unset the warehouse
          else if (this.portalOrder.warehouse && this.portalOrder.warehouse.bkWarehouseKey
            && this.availableWarehouses && this.availableWarehouses.length > 0
            && !this.availableWarehouses.find(w => w.bkWarehouseKey === this.portalOrder.warehouse.bkWarehouseKey)) {
            this.portalOrder.warehouse = undefined;
            this.orderCartService.clearProductsInCart();
          }
          this.getInitialDetailsValidation();
          this.moveStepsIfValuesSelected();
        }

        let wasPrefferedWarehouseSet = false;
        const obs = new Observable(observer => {
          if (!this.portalOrder.warehouse && this.availableWarehouses) {
            // If the order has no warehouse and there is only one available warehouse, set the warehouse to that warehouse
            if (this.availableWarehouses.length === 1) {
              this.portalOrder.warehouse = this.availableWarehouses[0];
              this.updateOrderByCustomer().subscribe({
                next: () => {
                  observer.next();
                  observer.complete();
                },
                error: error => observer.error(error)
              });
            }
            // If the order has no warehouse and there is more than one available warehouse, set the warehouse to the customer's preferred warehouse
            else if (this.currentCustomer) {
              this.customerSettingsStore.load(this.currentCustomer.bkCustomerKey).pipe(
                skipWhile(settings => settings === undefined), // Wait for the settings to load (null is returned if no settings are found)
                switchMap(settings => {
                  if (settings && settings.bkWarehouseKey) {
                    const preferredWarehouse = this.availableWarehouses.find(w => w.bkWarehouseKey === settings.bkWarehouseKey);
                    if (preferredWarehouse) {
                      this.portalOrder.warehouse = preferredWarehouse;
                      wasPrefferedWarehouseSet = true;
                    }
                  }
                  else {
                    this.portalOrder.warehouse = this.availableWarehouses[0];
                  }
                  return this.updateOrderByCustomer();
                })
              ).subscribe(() => {
                observer.next();
                observer.complete();
              });
            }
          }
          else {
            this.updateOrderByCustomer().subscribe(() => {
              observer.next();
              observer.complete();
            });
          }
        });

        obs.subscribe(() => {
          if (this.currentStep.stepName === 'warehouse' && wasPrefferedWarehouseSet) {
            this.currentStep = this.getStepByName('products');
          }
          this.loading = false;
          this.registerSaveOnAutomaticLogout();
        });
      },
      error: error => {
        console.error(error);
        this.kendoAlertService.showErrorAlert("alerts.errorDuringDataRetrieve");
      }
    });
  }

  updateOrderByCustomer(): Observable<CosmosCustomer> {
    if (this.portalOrder.customerGLNNumber) {
      this.initializeTermsAndConditionsPath(undefined);
      return of(null)
    }

    return this.customerService.getCustomerByCode(this.portalOrder.bkCustomerNumber).pipe(tap(customer => {
      this.updateCustomerGLNNumber(customer);
      if (!this.portalOrder.companyGLNNumber) {
        this.updateCompanyGLNNumber(customer);
      }
      this.initializeTermsAndConditionsPath(customer);
    }));
  }

  updateCustomerGLNNumber(customer: CosmosCustomer) {
    this.portalOrder.customerGLNNumber = customer.GLN;
  }

  updateCompanyGLNNumber(customer: CosmosCustomer) {
    this.customerService.getCompanyByCustomerRegisterCode(customer.TikkurilaCompanyRegisterCode).subscribe(company => {
      this.portalOrder.companyGLNNumber = company.glnNumber;
    });
  }

  initializeTermsAndConditionsPath(customer: CosmosCustomer | undefined) {
    switch (customer?.TikkurilaCompanyRegisterCode ?? "") {
      case "100": {
        this.termsAndConditionsPath = "../../assets/Tikkurila_Sverige_AB_-_Allmänna_Villkor_Leveransavtal.pdf";
        break;
      }
      case "210": {
        this.termsAndConditionsPath = "../../assets/Terms and Conditions for NO.pdf";
        break;
      }
      default: {
        this.termsAndConditionsPath = "../../assets/Tikkurila_Sverige_AB_-_Allmänna_Villkor_Leveransavtal.pdf";
        break;
      }
    }
  }

  registerSaveOnAutomaticLogout() {
    this.idleService.registerOnTimeoutFunction(this.trySaveOrder);
  }

  unRegisterSaveOnAutomaticLogout() {
    this.idleService.unregisterOnTimeoutFunction(this.trySaveOrder);
  }

  setupSaveOnIdle() {
    this.idleService.onIdleStartDouble.subscribe(() => {
      this.trySaveOrder();
    });
  }

  initializeOrderFromState() {
    const state = history.state;
    const orderId = this.params?.get("id");
    // If params has an id and the order is in the state, initialize the order from the state if the order id matches the id in the params
    if (state?.portalOrder && (!orderId || (state?.portalOrder?.portalOrderId && state?.portalOrder?.portalOrderId === orderId))) {
      this.portalOrder = state.portalOrder;
      this.initializeInventoryOnline();
      this.getInitialDetailsValidation();
      if (this.isSingleWarehouse) {
        this.portalOrder.warehouse = this.warehouse;
      }
      this.orderCartService.setCurrentOrder(this.portalOrder);
    }
    if (state?.productSearch) {
      this.productSearchValue = state.productSearch;
    }
    this.setCurrentStepFromState(state);
  }

  setCurrentStepFromState(state = undefined): boolean {
    state ??= history.state;
    if (state?.productSearch) {
      this.currentStep = this.getStepByName('products');
      return true;
    }
    if (state?.stepIdx) {
      this.currentStepIdx = state.stepIdx;
      return true;
    }
    return false;
  }

  initializeInventoryOnline() {
    this.portalOrder.orderProducts?.forEach(x => {
      if (!this.inventoryOnline.find(inv => inv.axCode === x.axCode)) {
        this.inventoryOnline.push({
          axCode: x.axCode,
          status: InventoryOnlineStatus.Loading
        } as InventoryOnline);
      }
    });
  }

  public setIsOrderUpdated(isUpdatedValue: boolean) {
    this.orderLocallyUpdated = isUpdatedValue;
  }

  public onOrderUpdatedInSelectProductsStep() {
    this.setIsOrderUpdated(true);
    this.trySaveOrder();
  }

  /**
   * Updates the online inventory for the portal order.
   */
  public updateInventoryOnline() {
    // Set the time after which inventory should be updated
    const updateAfterMilliseconds = 5 * 60 * 1000; // 5 minutes

    // Get the products that need to be updated
    const productsToUpdate: PortalOrderProduct[] = _getProductsToUpdate(_getInventoryToUpdate(this.inventoryOnline), this.portalOrder);

    // If there are products to update
    if (productsToUpdate.length > 0) {
      // Get the inventory for the warehouse and products
      this.inventoryService.getInventoryForWarehouseByProductsOnline(this.portalOrder.bkCompanyNumber, this.portalOrder.warehouse.bkWarehouseKey, productsToUpdate).subscribe(inventoryResponse => {
        const inv = inventoryResponse;

        // For each product in the portal order
        this.portalOrder.orderProducts.forEach(product => {
          // If the product is not in the inventory response
          if (!_isInventoryInResponse(inv, product)) {
            // Add the existing inventory value to the response if it exists
            const existingValue = this.inventoryOnline.find(inv => inv.axCode === product.axCode);
            if (existingValue) {
              inv.push(existingValue);
            }
            // Otherwise, add a new inventory value with status NotAvailable
            else {
              _pushInventoryNotAvailable(inv, product);
            }
          }
        });

        // Limit the available quantities to 9999
        inv.forEach(x => {
          x.availQtyInInventUnit = Math.min(9999, x.availQtyInInventUnit);
          x.availQtyInSalesUnit = Math.min(9999, x.availQtyInSalesUnit);
        })

        // Update the online inventory
        this.inventoryOnline = inv;
      });
    }

    // ------------ Function Private Methods ------------

    // Get the inventory items that need to be updated
    function _getInventoryToUpdate(currentInventoryOnline: InventoryOnline[]): InventoryOnline[] {
      if (!currentInventoryOnline) {
        return [];
      }

      const inventoryToUpdate = currentInventoryOnline.filter(item => item.status != InventoryOnlineStatus.NotAvailable
        && (item.status == InventoryOnlineStatus.Loading || Date.now() - item.dateUpdated.getTime() > updateAfterMilliseconds));

      inventoryToUpdate.forEach(item => item.status = InventoryOnlineStatus.Loading);

      return inventoryToUpdate;
    }

    // Get the products that need to be updated
    function _getProductsToUpdate(inventoryToUpdate: InventoryOnline[], order: PortalOrder): PortalOrderProduct[] {
      if (!inventoryToUpdate || inventoryToUpdate.length === 0) {
        return [];
      }

      return inventoryToUpdate.map(item => ({ axCode: item.axCode } as PortalOrderProduct))
        .filter(p => -1 !== order.orderProducts.findIndex(op => op.axCode === p.axCode));
    }

    // Check if the inventory response contains the specified product
    function _isInventoryInResponse(inv: InventoryOnline[], product: PortalOrderProduct): boolean {
      return !!inv.find(inv => inv.axCode === product.axCode);
    }

    // Add a new inventory value with status NotAvailable
    function _pushInventoryNotAvailable(inv: InventoryOnline[], product: PortalOrderProduct) {
      inv.push({
        axCode: product.axCode,
        status: InventoryOnlineStatus.NotAvailable,
        dateUpdated: new Date()
      } as InventoryOnline);
    }
  }

  onGetPricesStarted() {
    this.pricesUpdating = true;
  }

  onGetPricesFinished() {
    this.pricesUpdatedOn = new Date();
    this.pricesUpdating = false;
  }

  public orderShouldBeUpdated(): boolean {
    return this.orderLocallyUpdated;
  }

  public onNextButtonClicked(): void {
    const canMoveNext = this.canProceedToStep(this.nextStep.stepName);
    if (!canMoveNext) {
      return;
    }
    this.trySaveOrder(() => this.currentStepIdx++);
  }

  public onPreviousButtonClicked(): void {
    this.trySaveOrder(() => this.currentStepIdx--);
  }

  public onSaveButtonClicked() {
    this.trySaveOrder();
  }

  public onClearCartButtonClicked(decision: boolean) {
    if (decision) {
      this.orderCartService.clearProductsInCart();
    }
    this.closeConfirmClearCartDialog();
  }

  //TODO: Update this method
  public trySaveOrder = (sideEffect?: () => void): Observable<PortalOrder> => {
    let obs = this.orderShouldBeUpdated() ? this.createOrUpdateOrder() : of(null);
    if (sideEffect) {
      obs = obs.pipe(tap(sideEffect));
    }
    obs.subscribe();
    return obs;
  }

  public createOrUpdateOrder() {
    let obs: Observable<PortalOrder>;
    if (!this.isOrderInDB()) {
      obs = this.createOrderInDB();
    }
    else {
      obs = this.updateOrderInDB();
    }

    return obs.pipe(tap(() => {
      this.kendoAlertService.showSuccessAlert("alerts.draftSaved", true);
    }));
  }

  public isOrderInDB(): boolean {
    return !!this.portalOrder?.id;
  }

  public createOrderInDB(): Observable<PortalOrder> {
    if (this.portalOrder?.id != null) {
      return of(null);
    }
    this.orderUpdateInProgress = true;

    return this.portalOrderService.CreateOrder(this.portalOrder).pipe(
      catchError(() => {
        this.notificationService.showErrorAlert("alerts.unknownError");
        this.orderUpdateInProgress = false;
        this.setIsOrderUpdated(false); //reset order update flag
        if (this.currentAccount?.accountid) {
          this.router.navigate([`account/${this.currentAccount.accountid}/openorders`]);
        }
        else {
          this.router.navigate([`account`]);
        }
        return of(null);
      }),
      concatMap((x) => {
        const response = JSON.parse(x.message);
        this.portalOrder.id = response.id;
        this.portalOrder.portalOrderId = response.portalOrderId;
        this.portalOrder.createdOn = response.createdOn;
        this.portalOrder.modifiedOn = response.modifiedOn;

        return this.portalOrderService.GetPortalOrderDetailsById(
          this.currentAccount.ntw_axcode,
          this.portalOrder.id
        ).pipe(retry(3));
      }),
      tap({
        next: (portalOrder) => {
          this.orderUpdateInProgress = false;
          this.setIsOrderUpdated(false); //reset order update flag
          this.router.navigate([`./${portalOrder.portalOrderId ?? portalOrder.id}`], { relativeTo: this.activeRoute, state: { portalOrder: portalOrder, stepIdx: this.currentStepIdx } });
          return portalOrder;
        },
        error: (error) => {
          console.error(error);
          this.orderUpdateInProgress = false;
          return null;
        }
      })
    );
  }

  public updateOrderInDB(): Observable<PortalOrder> {
    this.orderLocallyUpdated = false;

    this.mergeProductsWithSameShades();

    if (this.portalOrder?.id == null) {
      this.notificationService.showErrorAlert("alerts.orderNotCreated");
      return of(null);
    }
    this.orderUpdateInProgress = true;

    if (this.portalOrder.orderProducts.some(x => x.quantity == null || x.quantity == 0)) {
      this.portalOrder.orderProducts.forEach(x => {
        if (x.quantity == null || x.quantity == 0) {
          x.quantity = 1;
        }
      });
    }

    return this.portalOrderService.UpdateOrder(this.portalOrder).pipe(
      concatMap(() => {
        return this.portalOrderService.GetPortalOrderDetailsById(
          this.currentAccount.ntw_axcode,
          this.portalOrder.id
        );
      }),
      tap({
        next: (portalOrder) => {
          this.orderUpdateInProgress = false;
          this.setIsOrderUpdated(false); //reset order update flag
          return portalOrder;
        },
        error: error => {
          this.notificationService.showErrorAlert(error);
          this.orderUpdateInProgress = false;
          return null;
        }
      })
    );
  }

  public submitOrder() {
    if (this.orderLocallyUpdated) {
      this.trySaveOrder(() => this.submitOrder());
      return;
    }
    if (this.isCompanyNorway) {
      return;
    }
    if (this.portalOrder?.id == null) {
      this.notificationService.showErrorAlert("alerts.orderNotCreated");
      return;
    }

    if (!this.verifyIfEverythingIsSelected()) {
      this.notificationService.showErrorAlert("alerts.missingOrderFields");
      return;
    }

    this.portalOrder.orderSubmissionStatus = PortalOrderStatus.Submitted;
    this.orderUpdateInProgress = true;
    this.portalOrderService.SubmitOrder(this.portalOrder).subscribe({
      next: () => {
        this.orderUpdateInProgress = false;
        this.notificationService.showSuccessAlert("alerts.orderSubmitted", true);
        this.router.navigate([`account/${this.currentAccount.accountid}/openorders`]);
      }, error: error => {
        this.notificationService.showErrorAlert(error);
        this.orderUpdateInProgress = false;
      }
    });
  }

  private mergeProductsWithSameShades() {
    if (!this.portalOrder?.orderProducts
      || this.portalOrder?.orderProducts?.length == 0
      || !this.portalOrder?.orderProducts.find(x => x.chosenColorShade)) {
      return;
    }

    const products = this.portalOrder.orderProducts.slice();
    const mergedProductArray: PortalOrderProduct[] = [];
    products.forEach(product => {
      if (!product.chosenColorShade) {
        mergedProductArray.push(product);
        return;
      }

      if (mergedProductArray.find(mergedProduct => mergedProduct?.axCode == product?.axCode)) {
        return;
      }

      const duplicateShades = products.filter(anotherProduct =>
        anotherProduct?.orderProductNumber !== product?.orderProductNumber
        && anotherProduct?.chosenColorShade?.Code === product?.chosenColorShade?.Code
        && product.axCode === anotherProduct.axCode);

      const mergedProduct = product;
      duplicateShades.forEach(duplicate => {
        mergedProduct.quantity += duplicate.quantity;
      });

      mergedProductArray.push(mergedProduct);
    });
    this.portalOrder.orderProducts = mergedProductArray;
  }

  public verifyIfEverythingIsSelected(): boolean {
    if (!this.portalOrder) {
      return false;
    }

    const warehouseSelected = this.portalOrder.warehouse;
    const productSelected = this.portalOrder.orderProducts?.length > 0;
    const addressesSelected = this.portalOrder.deliveryAddress;
    const detailsSelected = this.portalOrder.customerReference && !!this.portalOrder.customerRequisition;

    return warehouseSelected && productSelected && addressesSelected && detailsSelected;
  }

  public openConfirmClearCartDialog = () => {
    this.confirmClearCartDialogVisible = true;
  }

  public closeConfirmClearCartDialog = () => {
    this.confirmClearCartDialogVisible = false;
  }

  public saveAsDraftButtonVisible(): boolean {
    return this.orderShouldBeUpdated();
  }

  private moveStepsIfValuesSelected() {
    if (!this.portalOrder?.warehouse) {
      return;
    }

    const warehouseSelected = this.portalOrder.warehouse;
    const productsSelected = this.portalOrder.orderProducts?.length > 0 && this.productAmountSelected();

    //* state can be checked because this method is called only during order initialization
    //* to use this method in any other place, the logic should be updated or a new method should be created
    //? could use an initial render variable additionaly to avoid this issue?
    if (this.setCurrentStepFromState() || history?.state?.productSearch) {
      return;
    }
    if (warehouseSelected) {
      this.currentStep = this.getStepByName('products');
    }
    if (productsSelected && warehouseSelected) {
      this.currentStep = this.getStepByName('summary');
    }
  }

  private getInitialDetailsValidation() {
    if (this.portalOrder.customerReference && this.portalOrder.customerRequisition) {
      this.isDetailsStepValid = true;
    }
    else {
      this.isDetailsStepValid = false;
    }
  }

  private productAmountSelected(): boolean {
    if (!this.portalOrder?.orderProducts) {
      return false;
    }
    const productsWithoutQuantity = this.portalOrder?.orderProducts.filter(x => !x.quantity || x.quantity == 0);
    return productsWithoutQuantity?.length == 0;
  }

  public canProceedToStep(stepName: StepName): boolean {
    if (!this.portalOrder) {
      return false;
    }

    switch (stepName) {
      case 'warehouse': {
        return true;
      }
      case 'products': {
        return this.warehouseOk();
      }
      case 'summary': {
        return this.warehouseOk() && this.productsOk();
      }
      default: {
        return false;
      }
    }
  }

  warehouseOk(): boolean {
    return !!this.portalOrder.warehouse;
  }

  productsOk(): boolean {
    return !(!this.portalOrder.orderProducts || this.portalOrder.orderProducts?.length == 0
      || !this.productAmountSelected())
  }
}
