<ntw-loading-panel *ngIf="loading"></ntw-loading-panel>
<ng-container *ngIf="!loading">
    <div *ngIf="this.isReadOnly" class="row border-bottom mb-3 pb-3">
        <div class="col">
            <kendo-label class="label" text="{{'orderProcess.cannotChangeWarehouseIfProductsInCart' | translate}}">
                <kendo-svg-icon class="label-info" [icon]="this.icons.info" size="xlarge"></kendo-svg-icon>
            </kendo-label>
        </div>
    </div>
    <ntw-select-item-component [items]="this.displayedWarehouses" type="warehouse" [isHeaderOptionVisible]="true"
        [isReadOnly]="this.isReadOnly" headerOptionTitle="orderProcess.pickUpAddress"
        [title]="'orderProcess.warehouse'" [idField]="'bkWarehouseKey'"
        [(selectedItem)]="this.portalOrder.warehouse" [(isHeaderOptionChecked)]="this.portalOrder.isWarehousePickUpPoint"
        (selectedItemChange)="this.portalOrderChange.emit(this.portalOrder)"
        (isHeaderOptionCheckedChange)="this.onChangeIsPickupAddress()">
    </ntw-select-item-component>
</ng-container>