import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { IPortalOrderService } from 'src/app/portal-order/services/portal-orders.service.interface';
import { PortalOrder, PortalOrderBase, PortalOrderStatus } from '../models/order-creation/portal-order';
import { PortalOrderProduct } from '../models/order-creation/portal-order-product';
import { ProductWithInventoryAndQuantity } from '../models/order-creation/product-with-quantity';
import { SecurityService } from '../security/security.service';
import { ICustomerService } from './customer/customer.service.interface';

@Injectable({
    providedIn: 'root'
})

export class OrderCartService {
    private currentOrder: PortalOrder = null;

    constructor(private securityService: SecurityService,
        private customerService: ICustomerService,
        private portalOrderService: IPortalOrderService) {
    }

    public setCurrentOrder(order: PortalOrder) {
        this.currentOrder = order;
    }

    public getOrCreatePortalOrder(orderId: string): Observable<PortalOrder> {
        if (!orderId) {
            return of(this.createNewOrder())
        }
        const customerCode = this.securityService.getCurrentAccountObject()?.ntw_axcode;
        return this.portalOrderService.GetPortalOrderDetailsById(customerCode, orderId).pipe(tap(order => {
            return this.currentOrder = order;
        }));
    }

    public clearProductsInCart() {
        this.currentOrder.orderProducts = [];
    }

    public updateCartWithProduct(product: ProductWithInventoryAndQuantity) {
        if (!this.currentOrder || !product || !product.AXCode) {
            return;
        }
        const currentProducts = this.currentOrder.orderProducts;
        if (!currentProducts || currentProducts.length == 0) {
            const newProduct = new PortalOrderProduct(product);
            newProduct.orderProductNumber = 1;
            this.currentOrder.orderProducts = [newProduct];
            return;
        }

        const productIndex = currentProducts.findIndex(x => (x.axCode == product.AXCode) && !x.chosenColorShade);
        if (productIndex >= 0) {
            currentProducts[productIndex].quantity = product.Quantity;
        }
        else {
            const productNumber = currentProducts.length + 1;
            const newProduct = new PortalOrderProduct(product);
            newProduct.orderProductNumber = productNumber;
            this.currentOrder.orderProducts = [...currentProducts, newProduct];
        }
        this.currentOrder.orderProducts.forEach((p, i) => p.orderProductNumber = i + 1);
    }

    public getProductByAxCode(axCode: string): PortalOrderProduct {
        if (!this.currentOrder || !axCode) {
            return null;
        }

        const currentProducts = this.currentOrder.orderProducts;
        if (!currentProducts || currentProducts.length === 0) {
            return null;
        }

        const productIndex = currentProducts.findIndex(x => (x.axCode === axCode) && !x.chosenColorShade);
        if (productIndex >= 0) {
            return currentProducts[productIndex];
        }
        return null;
    }

    public createNewOrder(): PortalOrder {
        const accountAxCode = this.securityService.getCurrentAccountObject()?.ntw_axcode;

        const portalOrder: PortalOrderBase = {
            id: null,
            portalOrderId: null,
            createdOn: null,
            modifiedOn: null,
            submittedOn: null,
            warehouse: null,
            deliveryAddress: null,
            isWarehousePickUpPoint: false,
            orderProducts: null,
            customerReference: null,
            customerRequisition: null,
            bkCustomerNumber: accountAxCode,
            bkCompanyNumber: null,
            customerGLNNumber: null,
            companyGLNNumber: null,
            comments: "",
            requestedDeliveryDate: null,
            backOrdersPossible: false,
            ownerUserb2cId: this.securityService.getUser()?.localAccountId,
            LogicalName: "PortalOrder",
            orderSubmissionStatus: PortalOrderStatus.Draft
        };

        this.customerService.getCustomerByCode(portalOrder.bkCustomerNumber).pipe(concatMap(customer => {
            portalOrder.bkCompanyNumber = customer.TikkurilaCompanyRegisterCode;
            portalOrder.customerGLNNumber = customer.GLN;

            return this.customerService.getCompanyByCustomerRegisterCode(portalOrder.bkCompanyNumber);
        })).subscribe({
            next: company => {
                portalOrder.companyGLNNumber = company.glnNumber;
            },
            error: error => {
                console.error(error);
            }
        });

        this.currentOrder = portalOrder as PortalOrder;
        return this.currentOrder;
    }
}
