import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient } from '@angular/common/http';
import localeSwedish from '@angular/common/locales/sv';
import { ElementRef, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { NgIdleModule } from '@ng-idle/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { NOTIFICATION_CONTAINER, NotificationModule } from '@progress/kendo-angular-notification';
import 'hammerjs';
import { loginRequest, msalConfig, protectedResources } from '../environments/msal-configs/msal-config';
import { AccountModule } from './account/account.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InvoiceModule } from './invoice/invoice.module';
import { NavigationTopBarModule } from './navigation-top-bar/navigation-top-bar.module';
import { OrderProcessModule } from './order-process/order-process.module';
import { OrderModule } from './order/order.module';
import { RecentOrderModule } from './portal-order/portal-order.module';
import { ProductModule } from './products/product.module';
import { RoutingBreadCrumbsComponent } from './routing/routing-breadcrumbs.component';
import { SelectAccountComponent } from './select-account/select-account.component';
import { HasClaimDirective } from './shared/security/has-claim.directive';
import { SecurityService } from './shared/security/security.service';
import { SharedModule } from './shared/shared.module';
import { TaskModule } from './task/task.module';
import { UserWithoutRolesComponent } from './user-without-roles/user-without-roles.component';
import { UserGuideComponent } from './user-guide/user-guide/user-guide.component';


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(protectedResources.api.endpoint, protectedResources.api.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

registerLocaleData(localeSwedish);

@NgModule({
  declarations: [
    AppComponent,
    HasClaimDirective,
    SelectAccountComponent,
    RoutingBreadCrumbsComponent,
    UserWithoutRolesComponent,
    UserGuideComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      },
    }),
    NgIdleModule.forRoot(),
    AccountModule,
    OrderModule,
    InvoiceModule,
    ProductModule,
    OrderProcessModule,
    AppRoutingModule,
    MsalModule,
    NavigationTopBarModule,
    TaskModule,
    InputsModule,
    BrowserAnimationsModule,
    ButtonsModule,
    NavigationModule,
    RecentOrderModule,
    NotificationModule,
    SharedModule
  ],
  providers: [
    { provide: SecurityService, useClass: SecurityService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: NOTIFICATION_CONTAINER,
      useFactory: () => {
        return { nativeElement: document.body } as ElementRef;
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(handler: HttpBackend) {
  const http = new HttpClient(handler);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
